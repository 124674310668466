import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addContact,
  getContacts,
  getContact,
  editContact,
  deleteContact,
  getAllContacts,
} from "../../store/actions/contact_action";
import { SEARCH_TERMS } from "../enums/contacts_enum";

import { useSelectAllSource } from "../../shared/hooks/UseSource";
import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee";
import { useSelectAllClient } from "../../shared/hooks/UseClient";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllContacts = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contact);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  console.log("ID TO DELETE", deleteEntry);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getContacts({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteContact(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);
  useEffect(() => {
    // setPageNumber(1);
    dispatch(
      getContacts({
        pageNumber,
      })
    );
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleContact = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contact);
  useEffect(() => {
    dispatch(getContact(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateContact = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addContact(data));
  };
  return [addData];
};
export const useUpdateContact = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editContact(id, data));
  };
  return [updateData];
};

export const useSelectAllContact = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contact);
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  useEffect(() => {
    dispatch(getAllContacts({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [source] = useSelectAllSource();
  const [employee] = useSelectAllEmployee();
  const [client] = useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (source && source.all_sources) {
      const newData = source.all_sources.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, source: newData });
    }
  }, [source]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, contact_owner: newData });
    }
  }, [employee]);

  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.client_name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, client_name: newData });
    }
  }, [client]);

  return [dropdownOptions];
};
