import {
    GET_ADMINS,
    ADD_ADMIN,
    EDIT_ADMIN,
    GET_ADMIN,
    RESET_ADMIN,
    GET_ALL_ADMINS,
    ERROR_ADMIN,
} from "../types/admin_type";

const initialState = {
    admins: null,
    page: null,
    pages: null,
    admin: null,
    total_admins: 0,
    loading: true,
    error: {},
    admin_message: null,
    all_admins: null,
};

export const admin_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ADMINS:
            return {
                ...state,
                admins: payload.admins,
                pages: payload.pages,
                page: payload.page,
                total_admins: payload.count,
                loading: false,
                error: {},
            };
        case GET_ALL_ADMINS:
            return {
                ...state,
                all_admins: payload,
                loading: false,
                error: {},
            };

        case RESET_ADMIN:
            return {
                ...state,
                admin: null,
                loading: true,
                error: {},
            };

        case ADD_ADMIN:
            return {
                ...state,
                admin_message: payload,
                loading: false,
                error: {},
            };
        case GET_ADMIN:
            return {
                ...state,
                admin: payload,
                loading: false,
                error: {},
            };
        case EDIT_ADMIN:
            return {
                ...state,
                admin_message: payload,
                loading: false,
                error: {},
            };
        case ERROR_ADMIN:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
};
