import moment from "moment";

export const PAGE_TITLE = "Recruiters";
export const PAGE_SINGLE_TITLE = "Recruiters";
export const LINK_URL = "employees";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
  },
  username: {
    type: "string",
    required: true,
    title: "User name",
  },
  dob: {
    type: "string",
    inputType: "date",
    required: false,
    title: "Date Of Birth"
  },
  doj: {
    type: "string",
    inputType: "date",
    required: false,
    title: "Date Of Joining"
  },
  phone: {
    type: "string",
    required: false,
    title: "Phone",
  },
  password: {
    type: "string",
    required: false,
    title: "Password",
    hideOnView: true,

  },
  address: {
    type: "string",
    required: false,
    title: "Address",
  },
  emergency_contact_no: {
    type: "string",
    required: false,
    title: "Emergency Contact No.",
  },


  // user_role: {
  //   type: "related",
  //   required: false,
  //   title: "User Role",
  //   filed: "role",
  // }

};
export const initialValues = {
  name: "",
  email: "",
  user_name: "",
  dob: "",
  doj: "",
  phone: "",
  password: "",
  address: "",
  emergency_contact_no: "",
  user_role: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "User Name", value: "username" },
  { name: "Role", value: "role" },
  { name: "Date Of Birth", value: "dob" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "email",
    field: "email",
    label: "Email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "username",
    field: "username",
    label: "Username",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "phone",
    field: "phone",
    label: "phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "user_role",
    field: "user_role",
    label: "User Role",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
]
