export const PAGE_TITLE = "Holidays";
export const PAGE_SINGLE_TITLE = "Holiday";
export const LINK_URL = "holidays";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  holiday_date: {
    type: "string",
    required: true,
    title: "Holiday Date",
    inputType: "date",
  },
};
export const initialValues = {
  name: "",
  holiday_date: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Holiday Date", value: "holiday_date", date: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
