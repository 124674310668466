import moment from "moment";
export const PAGE_TITLE = "Candidates";
export const PAGE_SINGLE_TITLE = "Candidate";
// export const Filter_title = "CANDIDATE";
export const LINK_URL = "candidates";
export const SEARCH_TERMS = ["first_name", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
  },
  recruiter: {
    type: "related",
    required: true,
    title: "Recruiter",
    field: "name",
    // default:""

  },

  email_id: {
    type: "string",
    required: false,
    title: "Email ID",
  },
  cv_attachment: {
    type: "file",
    required: false,
    title: "CV Attachment",
    inputType: "",
    options: "",
    field: "",
  },
  client_name: {
    type: "related",
    required: false,
    title: "Client Name",
    inputType: "",
    options: "",
    field: "client_name",
  },
  position_name: {
    type: "related",
    required: false,
    title: "Position Name",
    field: "posting_title",
  },
  candidate_status: {
    type: "related",
    required: false,
    title: "Candidate Status",
    field: "name",
    static: true,
  },
  doj: {
    type: "string",
    required: false,
    title: "Date of Joining",
    inputType: "date",
  },
  source: {
    type: "string",
    required: false,
    title: "Source",
  },
  other_information_divider_2: {
    type: "divider",
    title: "Other Information",
  },
  phone_number: {
    type: "string",
    required: false,
    title: "Phone Number",
  },
  current_location: {
    type: "string",
    required: false,
    title: "Current Location",
  },
  other_information_divider_3: {
    type: "divider",
    title: "Other Information",
  },

  key_skills: {
    type: "string",
    required: false,
    title: "Key Skills",
  },
  total_experience: {
    type: "string",
    required: false,
    title: "Total Experience",
  },
  annual_salary: {
    type: "string",
    required: false,
    title: "Current CTC",
    inputType: "number",
  },
  expected_ctc: {
    type: "",
    required: false,
    title: "Expected CTC",
    inputType: "number",
  },
  offered_ctc: {
    type: "string",
    required: false,
    title: "Offered CTC",
    inputType: "number",

    // inputType: "date",
  },

  current_company_information_divider_4: {
    type: "divider",
    title: "Current Company Information",
  },
  curr_company_name: {
    type: "string",
    required: false,
    title: "Curr. Company name",
  },
  curr_company_duration_from: {
    type: "string",
    required: false,
    title: "Curr. Company duration from",
  },
  curr_company_designation: {
    type: "string",
    required: false,
    title: "Curr. Company Designation",
  },

  notice_period: {
    type: "string",
    required: false,
    title: "Notice Period",
  },

  resume: {
    type: "array",
    required: false,
    title: "Resume",
    fields: {
      resume: {
        type: "gallery",
        required: false,
        title: "Resume",
      },
    },
  },
};
export const initialValues = {
  name: "",
  recruiter: "",
  email_id: "",
  client_name: "",
  position_name: "",
  candidate_status: "",
  doj: "",
  source: "",
  phone_number: "",
  current_location: "",
  key_skills: "",
  total_experience: "",
  annual_salary: "",
  expected_ctc: "",
  offered_ctc: "",
  curr_company_name: "",
  curr_company_duration_from: "",
  curr_company_designation: "",
  notice_period: "",
  resume: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  // { name: "Phone Number", value: "phone_number" },
  { name: "Email ID", value: "email_id" },
  // { name: "Current Company", value: "curr_company_name" },
  { name: "Designation", value: "curr_company_designation" },
  { name: "Total Experience", value: "total_experience" },
  // { name: "Expected CTC", value: "expected_ctc" },
  // { name: "Notice Period", value: "notice_period" },
  { name: "Current Location", value: "current_location" },
  { name: "Date of Joining", value: "doj" },
  { name: "Source", value: "source" },
  {
    name: "Client Name",
    value: "client_name",
    related: true,
    field: "client_name",
    is_link: true,
    link_field: "clients",
  },
  {
    name: "Position Name",
    value: "position_name",
    related: true,
    field: "posting_title",
    is_link: true,
    link_field: "jobposts",
  },
  {
    name: "Recruiter",
    value: "recruiter",
    related: true,
    field: "name",
    is_link: true,
    link_field: "employees",
  },
  { name: "Candidate Status", value: "candidate_status" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "keyword",
    field: "text_unformatted_resume",
    label: "Keyword",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "email_id",
    field: "email_id",
    label: "Email ID",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "client_name",
    field: "client_name",
    label: "Client Name",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "candidate_status",
    field: "candidate_status",
    label: "Candidate Status",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "current_location",
    field: "current_location",
    label: "Current Location",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "total_experience",
    field: "total_experience",
    label: "Total Experience",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "annual_salary",
    field: "annual_salary",
    label: "Annual Salary",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "expected_ctc",
    field: "expected_ctc",
    label: "Expected CTC",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "notice_period",
    field: "notice_period",
    label: "Notice Period",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "resume_headline",
    field: "resume_headline",
    label: "Resume Headline",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "preferred_locations",
    field: "preferred_locations",
    label: "Preferred Location",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "functional_area",
    field: "functional_area",
    label: "Functional Area",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "role",
    field: "role",
    label: "Role",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "industry",
    field: "industry",
    label: "Industry",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "key_skills",
    field: "key_skills",
    label: "Key Skills",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "summary",
    field: "summary",
    label: "Summary",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "under_graduation_degree",
    field: "under_graduation_degree",
    label: "Under Graduation Degree",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
