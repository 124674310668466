import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    addActivity,
    getActivitys,
    getActivity,
    editActivity,
    deleteActivity,
    getAllActivitys,
} from "../../store/actions/activity_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllActivitys = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.activity);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteEntry, setDeleteEntry] = useState(null);

    useEffect(() => {
        if (deleteEntry) {
            dispatch(deleteActivity(deleteEntry));
        }
        allQuery();
    }, [deleteEntry, pageNumber, window.location.search]);
    const allQuery = useCallback(
        _debounce(() => {

            dispatch(
                getActivitys({
                    pageNumber,
                })
            );
        }, 1000),
        []
    );

    useEffect(() => {
        setPageNumber(1);
    }, [window.location.search]);

    const deleteBtnClicked = async (id) => {
        setDeleteEntry(id);
    };

    return [
        data, setPageNumber, deleteBtnClicked
    ];
};

// Get Single Data
export const useSingleActivity = (id) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.activity);
    useEffect(() => {
        dispatch(getActivity(id));
    }, [id]);
    return [data];
};
// Add Data
export const useCreateActivity = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.activity);
    const addData = async (data) => {
        await dispatch(addActivity(data));
    };
    return [data, addData];
};
export const useUpdateActivity = () => {
    const dispatch = useDispatch();
    // const data = useSelector((state) => state.Activity);
    const updateData = async (id, data) => {
        await dispatch(editActivity(id, data));
    };
    return [updateData];
};

export const useSelectAllActivity = () => {
    const dispatch = useDispatch();
    const [term, setTerm] = useState("");
    const [value, setValue] = useState("");
    const data = useSelector((state) => state.activity);
    useEffect(() => {
        dispatch(getAllActivitys({ term, value }));
    }, [term, value]);
    return [data, setTerm, setValue];
};


export const useGetDropdownOptions = () => {
    //  const [client, setClientSearchField, setClientSearchValue] =
    // useSelectAllClient();

    const [dropdownOptions, setDropdownOptions] = useState({});
    // useEffect(() => {
    //   if (industry && industry.all_industrys) {
    //     const newData = industry.all_industrys.map((item) => {
    //       return { label: item.name, value: item.name };
    //     });
    //     setDropdownOptions({ ...dropdownOptions, industry: newData });
    //   }
    // }, [industry]);
    const loadOptions = async (inputValue, callback, field) => {
        // if (field == "parent_category") {
        //   await setCategorySearchField("name");
        //   await setCategorySearchValue(inputValue);
        //   callback(dropdownOptions.parent_category);
        // }
    };


    return [dropdownOptions, loadOptions];
};