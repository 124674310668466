import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addSkillset,
  getSkillsets,
  getSkillset,
  editSkillset,
  deleteSkillset,
  getAllSkillsets,
} from "../../store/actions/skillset_action";
import { SEARCH_TERMS } from "../enums/skillsets_enum";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllSkillsets = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.skillset);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getSkillsets({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteSkillset(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);
  useEffect(() => {
    // setPageNumber(1);
    dispatch(
      getSkillsets({
        pageNumber,
      })
    );
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleSkillset = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.skillset);
  useEffect(() => {
    dispatch(getSkillset(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateSkillset = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addSkillset(data));
  };
  return [addData];
};
export const useUpdateSkillset = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editSkillset(id, data));
  };
  return [updateData];
};

export const useSelectAllSkillset = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.skillset);
  useEffect(() => {
    dispatch(getAllSkillsets({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};
