import {
  GET_CLOSEDCANDIDATES_STATED,
  GET_CLOSEDCANDIDATES,
  GET_CLOSEDCANDIDATES_ENDED,
  ADD_CLOSEDCANDIDATE_STATED,
  ADD_CLOSEDCANDIDATE,
  ADD_CLOSEDCANDIDATE_ENDED,
  EDIT_CLOSEDCANDIDATE_STATED,
  EDIT_CLOSEDCANDIDATE,
  EDIT_CLOSEDCANDIDATE_ENDED,
  GET_CLOSEDCANDIDATE_STATED,
  GET_CLOSEDCANDIDATE,
  GET_CLOSEDCANDIDATE_ENDED,
  GET_ALL_CLOSEDCANDIDATES_STATED,
  GET_ALL_CLOSEDCANDIDATES,
  GET_ALL_CLOSEDCANDIDATES_ENDED
} from "../types/closedcandidate_type";

const initialState = {
  closedcandidates_loading: true,
  closedcandidates: null,
  page: null,
  pages: null,
  total_closedcandidates: 0,

  closedcandidate: null,
  closedcandidate_loading: null,

  loading: true,

  closedcandidate_message: null,
  all_closedcandidates: null,
  all_closedcandidates_loading: null,
  add_closedcandidate_loading: true,
  edit_closedcandidate_loading: true
};

export const closedcandidate_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLOSEDCANDIDATES_STATED:
      return {
        ...state,
        closedcandidates: null,
        pages: null,
        page: null,
        total_closedcandidates: 0,
        closedcandidates_loading: true
      };
    case GET_CLOSEDCANDIDATES:
      return {
        ...state,
        closedcandidates: payload.closedcandidates,
        pages: payload.pages,
        page: payload.page,
        total_closedcandidates: payload.count
      };
    case GET_CLOSEDCANDIDATES_ENDED:
      return {
        ...state,
        closedcandidates_loading: false
      };
    case GET_ALL_CLOSEDCANDIDATES_STATED:
      return {
        ...state,
        all_closedcandidates_loading: true,
        all_closedcandidates: null
      };
    case GET_ALL_CLOSEDCANDIDATES:
      return {
        ...state,
        all_closedcandidates: payload
      };
    case GET_ALL_CLOSEDCANDIDATES_ENDED:
      return {
        ...state,
        all_closedcandidates_loading: false
      };

    case ADD_CLOSEDCANDIDATE_STATED:
      return {
        ...state,
        closedcandidate_message: null,
        add_closedcandidate_loading: true
      };
    case ADD_CLOSEDCANDIDATE:
      return {
        ...state,
        closedcandidate_message: payload
      };
    case ADD_CLOSEDCANDIDATE_ENDED:
      return {
        ...state,
        add_closedcandidate_loading: false
      };
    case GET_CLOSEDCANDIDATE_STATED:
      return {
        ...state,
        closedcandidate: null,
        closedcandidate_loading: true
      };
    case GET_CLOSEDCANDIDATE:
      return {
        ...state,
        closedcandidate: payload
      };
    case GET_CLOSEDCANDIDATE_ENDED:
      return {
        ...state,
        closedcandidate_loading: false
      };
    case EDIT_CLOSEDCANDIDATE_STATED:
      return {
        ...state,
        closedcandidate_message: null,
        edit_closedcandidate_loading: true
      };
    case EDIT_CLOSEDCANDIDATE:
      return {
        ...state,
        closedcandidate_message: payload
      };
    case EDIT_CLOSEDCANDIDATE_ENDED:
      return {
        ...state,
        edit_closedcandidate_loading: false
      };

    default:
      return state;
  }
};
