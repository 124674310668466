import api from "../../domain/api";
import {
  GET_USERROLES,
  ADD_USERROLE,
  EDIT_USERROLE,
  GET_USERROLE,
  RESET_USERROLE,
  GET_ALL_USERROLES,
  ERROR_USERROLE,
} from "../types/userrole_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addUserrole = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/userroles`, formData);
    dispatch({
      type: ADD_USERROLE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getUserroles =
  ({
    pageNumber = "",
    start_from = "",
    start_to = "",
    queryParams
  }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: RESET_USERROLE,
        });
        const { data } = await api.get(
         
          `/userroles?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
        );

        dispatch({
          type: GET_USERROLES,
          payload: data,
        });
      } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };
export const getUserrole = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_USERROLE,
    });
    const { data } = await api.get(`/userroles/${id}`);

    dispatch({
      type: GET_USERROLE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editUserrole = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/userroles/${id}`, formData);
    dispatch({
      type: EDIT_USERROLE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteUserrole = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/userroles/${id}`);
    dispatch(setAlert("Userrole Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllUserroles = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/userroles/all`);

    dispatch({
      type: GET_ALL_USERROLES,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_USERROLE });
};
