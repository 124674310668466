import {
  GET_SOURCES,
  ADD_SOURCE,
  EDIT_SOURCE,
  GET_SOURCE,
  RESET_SOURCE,
  GET_ALL_SOURCES,
  ERROR_SOURCE,
} from "../types/source_type";

const initialState = {
  sources: null,
  page: null,
  pages: null,
  source: null,
  total_sources: 0,
  loading: true,
  error: {},
  source_message: null,
  all_sources: null,
};

export const source_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SOURCES:
      return {
        ...state,
        sources: payload.sources,
        pages: payload.pages,
        page: payload.page,
        total_sources: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_SOURCES:
      return {
        ...state,
        all_sources: payload,
        loading: false,
        error: {},
      };

    case RESET_SOURCE:
      return {
        ...state,
        source: null,
        loading: true,
        error: {},
      };

    case ADD_SOURCE:
      return {
        ...state,
        source_message: payload,
        loading: false,
        error: {},
      };
    case GET_SOURCE:
      return {
        ...state,
        source: payload,
        loading: false,
        error: {},
      };
    case EDIT_SOURCE:
      return {
        ...state,
        source_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_SOURCE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
            