import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { TextInput, SelectBox } from "../../components/Form/TextInput";
import { useSelectAllCandidatestatus } from "../../shared/hooks/UseCandidatestatus";
import { useSelectAllCandidate } from "../../shared/hooks/UseCandidate";
import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee";
import { convertToFormData } from "../../shared/upload";
import { useCreateInterviewCandidate } from "../../shared/hooks/UseInterview";

const ScheduleInterview = ({ job_post_id, jobpost }) => {
  let history = useHistory();

  const [addData] = useCreateInterviewCandidate();

  const [candidateStatus] = useSelectAllCandidatestatus();
  const [candidate] = useSelectAllCandidate();
  const [employee] = useSelectAllEmployee();
  const { loading, all_candidatestatuss } = candidateStatus;
  const { all_candidates } = candidate;
  const { all_employees } = employee;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);

  const submitFormClicked = async (values) => {
    // if (featuredImage) {
    //   const image = await upload(featuredImage);
    //   if (image) {
    //     values.job_summary = image;
    //   }
    // }
    // if (gallery) {
    //   const galleryImage = await upload(gallery[0]);
    //   if (galleryImage) {
    //     values.other = galleryImage;
    //   }
    // }
    // await addData(values);
    // history.push(`/${LINK_URL}`);

    const data = await convertToFormData({ values, featuredImage });
    console.log(data);
    await addData(data);
    history.push(`/interviews`);
  };
  return (
    <div>
      {jobpost && (
        <Formik
          initialValues={{
            interview_type: "",
            name: "",
            phone_number: "",
            email_id: "",
            candidate_status: "",
            candidate: "",
            // job_post: "",
            from: "",
            to: "",
            interviewer: "",
            location: "",
            recruiter: "",
            // cv_upload: "",
          }}
          validationSchema={Yup.object({
            interview_type: Yup.string().required("Required"),
            name: Yup.string().required("Required"),
            phone_number: Yup.string().required("Required"),
            email_id: Yup.string().required("Required"),
            candidate_status: Yup.string().required("Required"),
            // candidate: Yup.string().required("Required"),
            // job_post: Yup.string().required("Required"),
            from: Yup.string().required("Required"),
            to: Yup.string().required("Required"),
            interviewer: Yup.string().required("Required"),
            // location: Yup.string().required("Required"),
            // cv_upload: Yup.string().required("Required"),
            // status: Yup.string().required("Required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            console.log("Form Data", values);
            values.posting_title = job_post_id;
            // jobpost;
            await submitFormClicked(values);
            setSubmitting(false);
            resetForm(true);
          }}
        >
          {(formik) => {
            console.log(formik);
            return (
              <Form autoComplete={"off"}>
                <div className="inner-box" style={{ padding: 20 }}>
                  <h3>Schedule Interview</h3>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-6 pb-14px ">
                      <TextInput
                        label="Interview Type"
                        name="interview_type"
                        type="select"
                        // option=[""]
                      />
                    </div>
                    <div className="col-md-6 pb-14px">
                      <SelectBox
                        label="Candidate"
                        name="candidate"
                        type="select"
                      >
                        <option value="">Select</option>
                        {all_candidates &&
                          all_candidates.map((item) => {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          })}
                      </SelectBox>
                    </div>
                    <div className="col-md-6 pb-14px">
                      <SelectBox
                        label="Recruiter"
                        name="recruiter"
                        type="select"
                      >
                        <option value="">Select</option>
                        {all_employees &&
                          all_employees.map((item) => {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          })}
                      </SelectBox>
                    </div>
                    <div className="col-md-6 pb-14px">
                      <TextInput
                        label="From"
                        name="from"
                        type="datetime-local"
                      />
                    </div>
                    <div className="col-md-6 pb-14px">
                      <TextInput label="To" name="to" type="datetime-local" />
                    </div>

                    <div className="col-md-6 pb-14px">
                      <TextInput label="Interviewer" name="interviewer" />
                    </div>
                    <div className="col-md-6 pb-14px">
                      <TextInput
                        label="Location"
                        name="location"
                        type="related"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="p-2 mt-2 mb-2 bg-light">
                        <p className="font-weight-bold">
                          {" "}
                          Candidate Information{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 pb-14px">
                      <TextInput
                        label="Candidate Name"
                        name="name"
                        type="text"
                      />
                    </div>
                    <div className="col-md-6 pb-14px">
                      <TextInput
                        label="Candidate Phone"
                        name="phone_number"
                        type="text"
                      />
                    </div>
                    <div className="col-md-6 pb-14px">
                      <TextInput
                        label="Candidate Email"
                        name="email_id"
                        type="text"
                      />
                    </div>
                    <div className="col-md-6 pb-14px">
                      <SelectBox
                        label="Candidate Status"
                        name="candidate_status"
                        type="select"
                      >
                        <option value="">Select</option>
                        {all_candidatestatuss &&
                          all_candidatestatuss.map((item) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );
                          })}
                      </SelectBox>
                    </div>
                    <div className="col-md-6 pb-14px">
                      <TextInput
                        label="Upload CV"
                        name="upload_cv"
                        type="file"
                        onChange={(e) =>
                          setFeaturedImage({ upload_cv: e.target.files[0] })
                        }
                      ></TextInput>
                    </div>
                  </div>
                  <div className="row pr-35px">
                    <div className="col-md-12 text-center m-3 gt-button">
                      <button
                        type="submit"
                        className="btn btn-md btn-primary m-3"
                      >
                        {formik.isSubmitting ? "Processing..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default ScheduleInterview;
