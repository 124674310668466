import moment from "moment";
export const PAGE_TITLE = "Admins";
export const PAGE_SINGLE_TITLE = "Admin";
export const LINK_URL = "admins";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
  },
  username: {
    type: "string",
    required: true,
    title: "User name",
  },
  dob: {
    type: "string",
    inputType: "date",
    required: false,
    title: "Date Of Birth",
  },
  doj: {
    type: "string",
    inputType: "date",
    required: false,
    title: "Date Of Joining",
  },
  phone: {
    type: "string",
    required: false,
    title: "Phone",
  },
  password: {
    type: "string",
    required: false,
    title: "Password",
    // hideOnView: true,
    // hideOnEntry: true,
  },
  address: {
    type: "string",
    required: false,
    title: "Address",
  },
  emergency_contact_no: {
    type: "string",
    required: false,
    title: "Emergency Contact No.",
  },
  linkedin_profile: {
    type: "string",
    required: true,
    title: "LinkedIn Profile",
  },
  role: {
    type: "select",
    required: false,
    title: "User Role",
    options: ["RECRUITER", "SUPER ADMIN", "MANAGER", "BD"],
  },
  manager: {
    type: "related",
    required: false,
    title: "Manager",
    field: "name",
  },
};
export const initialValues = {
  name: "",
  email: "",
  user_name: "",
  dob: "",
  phone: "",
  password: "",
  address: "",
  emergency_contact_no: "",
  role: "",
  manager: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "User Name", value: "username" },
  { name: "Role", value: "role" },
  { name: "Date Of Birth", value: "dob" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
  },
  {
    id: "email",
    field: "email",
    label: "Email",
    type: "string",
  },
  {
    id: "username",
    field: "username",
    label: "Username",
    type: "string",
  },
  {
    id: "dob",
    field: "dob",
    label: "Dateofbirth",
    type: "date",
  },
  {
    id: "phone",
    field: "phone",
    label: "phone",
    type: "string",
  },

  // {
  //   field: "password",
  //   label: "Password",
  //   type: "string",
  // },
  // {
  //   field: "address",
  //   label: "Address",
  //   type: "string",
  // },
  // {
  //   field: "emergency_contact_no",
  //   label: "Emergency Contact No",
  //   type: "string",
  // },
  {
    id: "role",
    field: "role",
    label: "User Role",
    type: "string",
  },
  {
    id: "manager",
    field: "manager",
    label: "Manager",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
];
