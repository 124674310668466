import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addSource,
  getSources,
  getSource,
  editSource,
  deleteSource,
  getAllSources,
} from "../../store/actions/source_action";
import { SEARCH_TERMS } from "../enums/sources_enum";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllSources = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.source);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getSources({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteSource(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  useEffect(() => {
    // setPageNumber(1);
    dispatch(
      getSources({
        pageNumber,
      })
    );
  }, [pageNumber]);
  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleSource = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.source);
  useEffect(() => {
    dispatch(getSource(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateSource = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addSource(data));
  };
  return [addData];
};
export const useUpdateSource = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editSource(id, data));
  };
  return [updateData];
};

export const useSelectAllSource = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.source);
  useEffect(() => {
    dispatch(getAllSources({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};
