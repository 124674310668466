import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { client_reducer } from "./client_reducer";
import { jobpost_reducer } from "./jobpost_reducer";
import { userrole_reducer } from "./userrole_reducer";
import { applicant_reducer } from "./applicant_reducer";
import { employee_reducer } from "./employee_reducer";
import { cv_reducer } from "./cv_reducer";
import { candidate_reducer } from "./candidate_reducer";
import { skillset_reducer } from "./skillset_reducer";
import { source_reducer } from "./source_reducer";
import { candidatestatus_reducer } from "./candidatestatus_reducer";
import { industry_reducer } from "./inudstry_reducer";
import { contact_reducer } from "./contact_reducer";
import { interview_reducer } from "./interview_reducer";
import { notification_reducer } from "./notification_reducer";
import { dashboard_reducer } from "./dashboard_reducer";
import { admin_reducer } from "./admin_reducer";
import { attendance_reducer } from "./attendance_reducer";
import { closedcandidate_reducer } from "./closedcandidate_reducer";
import { holiday_reducer } from "./holiday_reducer";
import { activity_reducer } from "./activity_reducer";
import { report_reducer } from "./report_reducer";

export default combineReducers({
  alert,
  auth,
  client: client_reducer,
  jobpost: jobpost_reducer,
  userrole: userrole_reducer,
  applicant: applicant_reducer,
  employee: employee_reducer,
  cv: cv_reducer,
  candidate: candidate_reducer,
  skillset: skillset_reducer,
  source: source_reducer,
  candidatestatus: candidatestatus_reducer,
  industry: industry_reducer,
  contact: contact_reducer,
  interview: interview_reducer,
  notification: notification_reducer,
  dashboard: dashboard_reducer,
  admin: admin_reducer,
  attendance: attendance_reducer,
  closedcandidate: closedcandidate_reducer,
  holiday: holiday_reducer,
  activity: activity_reducer,
  report: report_reducer,
});
