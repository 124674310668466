import React from 'react'
import Header from "../../components/template/Header";
import BreadCrumb from "../../components/template/BreadCrumb";
import {
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
} from "../../shared/enums/employees_enum";
import { useSelectAllEmployee } from '../../shared/hooks/UseEmployee';


function EmployeeReport() {


    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Recruiters Report"
                    mainLinkTitle="Recruiter"
                    mainLinkUrl="/employees"
                    activeLink={PAGE_TITLE}
                />

                <section className='employee-report-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="employee-reports-content">

                                    <div className="employee-reports-title">
                                        <p>Recruiters Report:</p>
                                    </div>

                                    <div className="employee-report-table">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Recruiter</th>
                                                    <th scope="col">Jobpost Closed By Recruiter</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>Mark</td>
                                                    <td>Otto</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">2</th>
                                                    <td>Jacob</td>
                                                    <td>Thornton</td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        </div>
    )
}

export default EmployeeReport