import React, { useEffect } from 'react';
import ExportComponent from '../../components/common/ExportComponent';
import { inputFields } from '../../shared/enums/jobposts_enum';
import { UseDataForExcel, UseFilter } from '../../shared/hooks/UseExcel';
import {
  useAllJobpostsByCondition,
  useSelectAllJobpost,
} from '../../shared/hooks/UseJobpost';

export default function ExportJobPost() {
  const [jobPostData, setTerm, setValue] = useSelectAllJobpost();

  const [exportXLSXData] = UseFilter();
  const { all_jobposts } = jobPostData;
  const [convertToReadable, exportData] = UseDataForExcel();

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Requirements', 'requirements');
  };

  useEffect(() => {
    if (all_jobposts) {
      convertToReadable(all_jobposts, inputFields);
    }
  }, [all_jobposts]);
  useEffect(() => {
    setValue('Closed By Accord');
    setTerm('job_opening_status');
  }, []);
  return (
    <>
      <ExportComponent
        label={'Export Job Posts'}
        handleOnExport={handleOnExport}
      />
    </>
  );
}
