import {
    GET_NOTIFICATIONS,
    ADD_NOTIFICATION,
    EDIT_NOTIFICATION,
    GET_NOTIFICATION,
    RESET_NOTIFICATION,
    GET_ALL_NOTIFICATIONS,
    ERROR_NOTIFICATION,
} from "../types/notification_type";

const initialState = {
    notifications: null,
    page: null,
    pages: null,
    notification: null,
    total_notifications: 0,
    loading: true,
    error: {},
    notification_message: null,
    all_notifications: null,
};

export const notification_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload.notifications,
                pages: payload.pages,
                page: payload.page,
                total_notifications: payload.count,
                loading: false,
                error: {},
            };
        case GET_ALL_NOTIFICATIONS:
            return {
                ...state,
                all_notifications: payload,
                loading: false,
                error: {},
            };

        case RESET_NOTIFICATION:
            return {
                ...state,
                notification: null,
                loading: true,
                error: {},
            };

        case ADD_NOTIFICATION:
            return {
                ...state,
                notification_message: payload,
                loading: false,
                error: {},
            };
        case GET_NOTIFICATION:
            return {
                ...state,
                notification: payload,
                loading: false,
                error: {},
            };
        case EDIT_NOTIFICATION:
            return {
                ...state,
                notification_message: payload,
                loading: false,
                error: {},
            };
        case ERROR_NOTIFICATION:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
};
