import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addIndustry,
  getIndustrys,
  getIndustry,
  editIndustry,
  deleteIndustry,
  getAllIndustrys,
} from "../../store/actions/industry_action";
import { SEARCH_TERMS } from "../enums/industrys_enum";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllIndustrys = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.industry);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getIndustrys({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteIndustry(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    // setPageNumber(1);
    dispatch(
      getIndustrys({
        pageNumber,
      })
    );
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleIndustry = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.industry);
  useEffect(() => {
    dispatch(getIndustry(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateIndustry = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addIndustry(data));
  };
  return [addData];
};
export const useUpdateIndustry = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editIndustry(id, data));
  };
  return [updateData];
};

export const useSelectAllIndustry = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.industry);
  useEffect(() => {
    dispatch(getAllIndustrys({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};
