import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import Filters from "../../components/common/Filters";
import AddBtn from "../../components/common/AddBtn";
import {
  SEARCH_TERMS,
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/interviews_enum";
import DataTable from "../../components/common/DataTable";
import { useAllInterviews, useAllInterviewsStats } from "../../shared/hooks/UseInterview";
import SidebarFilter from "../../components/common/SidebarFilter";

import { useSelectAllInterview } from "../../shared/hooks/UseInterview";
import { useSelectAllCandidate } from "../../shared/hooks/UseCandidate";
import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee";
import { useSelectAllJobpost } from "../../shared/hooks/UseJobpost";
import { useSelectAllCandidatestatus } from "../../shared/hooks/UseCandidatestatus";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { Link } from "react-router-dom";

const AllInterviews = ({ }) => {
  const [data, setPageNumber, deleteBtnClicked] = useAllInterviews();
  const [statsdata, setParams] = useAllInterviewsStats();
  const { loading,
    interviews,
    total_interviews,
    interviews_stats_loading,
    interviews_stats,
    page, pages } = data;

  const [jobpost] = useSelectAllJobpost();
  const [interview] = useSelectAllInterview();
  const [candidate] = useSelectAllCandidate();
  const [employee] = useSelectAllEmployee();
  const [candidatestatus] = useSelectAllCandidatestatus();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (jobpost && jobpost.all_jobposts) {
      const newData = jobpost.all_jobposts.map((item) => {
        return {
          label: `${item.posting_title}-(${item.client_name && item.client_name.client_name
            })`,
          value: item._id,
        };
      });
      setDropdownOptions({ ...dropdownOptions, posting_title: newData });
    }
  }, [jobpost]);
  // console.log(jobpost.all_jobposts);
  // useEffect(() => {
  //     if (jobpost && jobpost.all_jobposts) {
  //         const newData = jobpost.all_jobposts.map((item) => {
  //             return { label: item.client_name, value: item._id };
  //         });
  //         setDropdownOptions({ ...dropdownOptions, posting_client: newData });
  //     }
  // }, [jobpost]);

  // useEffect(() => {
  //     if (interview && interview.all_interviews) {
  //         const newData = interview.all_interviews.map((item) => {
  //             return { label: item.interview_name, value: item._id };
  //         });
  //         setDropdownOptions({ ...dropdownOptions, posting_title: newData });
  //     }
  // }, [interview]);

  useEffect(() => {
    if (candidate && candidate.all_candidates) {
      const newData = candidate.all_candidates.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, candidate: newData });
    }
  }, [candidate]);

  useEffect(() => {
    if (candidatestatus && candidatestatus.all_candidatestatuss) {
      const newData = candidatestatus.all_candidatestatuss.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, candidate_status: newData });
    }
  }, [candidatestatus]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, interviewer: newData });
    }
  }, [employee]);

  console.log("interviews_stats", interviews_stats);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />
        <FilterDateComponent link="/interviews" />

        <div className="container-fluid">
          <div className="row">
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
              />
            )}
            <div className="col-lg-9">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      {total_interviews} records found
                    </div>

                    {interviews_stats &&
                      interviews_stats.map((item) => {
                        if (item._id) {
                          return (
                            <div className="col-md-2">
                              <div className="card">
                                <div className="card-body">
                                  <Link to={`/interviews?exact[candidate_status]=${item._id}`}>
                                    <div>{item._id}</div>
                                  </Link>

                                  <Link to={`/interviews?exact[candidate_status]=${item._id}`}>
                                    <div>{item.count}</div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>

                  <div>
                    <DataTable
                      keys={view_all_table}
                      data={interviews}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    />
                    <Pagination
                      data={interviews}
                      page={page}
                      pages={pages}
                      count={total_interviews}
                      setPage={setPageNumber}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInterviews;
