import React, { useState } from 'react'
import Header from "../../components/template/Header";
import moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    useAllAttendances
} from "../../shared/hooks/UseAttendance";
import { useHistory, useLocation } from "react-router";
import * as qs from "qs";
import { useEffect } from 'react';
function AttendaceByDate() {
    const [date, setDate] = useState(new Date());
    const history = useHistory();
    const location = useLocation();
    const handleSidebarChange = ({ name, value, type = "search", condition }) => {
        const queryParams = qs.parse(window.location.search.replace("?", ""));
        if (type == "conditional") {
            console.log(name, value, type, condition);
            if (queryParams[type]) {
                if (queryParams[type][name]) {
                    if (value && value.length > 0) {
                        queryParams[type][name][condition ? condition : "$gte"] = value;
                    } else {
                        delete queryParams[type][name][condition ? condition : "$gte"];
                    }
                } else {
                    queryParams[type][name] = {};
                    if (value && value.length > 0) {
                        queryParams[type][name][condition ? condition : "$gte"] = value;
                    } else {
                        delete queryParams[type][name][condition ? condition : "$gte"];
                    }
                }
            } else {
                queryParams[type] = {};
                queryParams[type][name] = {};
                if (value && value.length > 0) {
                    queryParams[type][name][condition ? condition : "$gte"] = value;
                } else {
                    delete queryParams[type][name][condition ? condition : "$gte"];
                }
            }
        } else {
            if (queryParams[type]) {
                if (value && value.length > 0) {
                    queryParams[type][name] = value;
                } else {
                    delete queryParams[type][name];
                }
            } else {
                queryParams[type] = {};
                if (value && value.length > 0) {
                    queryParams[type][name] = value;
                } else {
                    delete queryParams[type][name];
                }
            }
        }

        const query = qs.stringify(queryParams, {
            encodeValuesOnly: true, // prettify url
        });
        // console.log("PARAMS", params.toString());
        // console.log("QUERY", `${location.pathname}?${query}`);
        console.log("QUERY TO BE PRINT", query);
        history.push(`${location.pathname}?${query}`);
    };
    const onChange = (date) => {
        return (
            setDate(date)
        )
    }

    // const [value, onChange] = useState(new Date());
    const [data] = useAllAttendances();
    const { attendances } = data;
    console.log(data);
    useEffect(() => {
        if (date) {
            console.log("DATE IS CHANGED", date);
            handleSidebarChange({ name: "createdAt", value: moment(date).startOf('day').toString(), type: "conditional", condition: "$gt" })
            handleSidebarChange({ name: "createdAt", value: moment(date).endOf('day').toString(), type: "conditional", condition: "$lt" })
        }
    }, [date])
    console.log("Date -", date)

    return (
        <div>
            <Header />
            <div style={{ margin: "0 ", marginTop: "150px" }}>
                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <p>
                                                <strong>Attendance By Date</strong>
                                            </p>
                                            {date.toDateString()}
                                        </div>
                                        <Calendar onChange={onChange} value={date} />

                                        <div className="calendar-selected-date pt-3">
                                            <p>{date.toDateString()}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="card">

                                    <div className="card-body">
                                        <table className="table table-stripped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th> # </th>
                                                    <th> Employee </th>
                                                    <th> Check In Time </th>
                                                    <th> Check Out Time </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {attendances && attendances.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td> {index + 1} </td>
                                                            <td>{item.employee && item.employee.name}</td>
                                                            <td>
                                                                {item.check_in_time ? moment(item.check_in_time).format("hh:mm A") : "-"}
                                                            </td>
                                                            <td>
                                                                {item.check_out_time ? moment(item.check_out_time).format("hh:mm A") : "-"}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AttendaceByDate