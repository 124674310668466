export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const GET_CLIENT = "GET_CLIENT";
export const RESET_CLIENT = "RESET_CLIENT";
export const ERROR_CLIENT = "ERROR_CLIENT";
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";

export const CLIENT_STATS_STARTED = "CLIENT_STATS_STARTED";
export const CLIENT_STATS = "CLIENT_STATS";
export const CLIENT_STATS_ENDED = "CLIENT_STATS_ENDED";

export const GET_ALL_CLIENTS_STATED = "GET_ALL_CLIENTS_STATED";
export const GET_ALL_CLIENTS_ENDED = "GET_ALL_CLIENTS_ENDED";

