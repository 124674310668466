import moment from 'moment';
import React, { useState, useEffect } from 'react'

function UpcomingBirthdays({ employees }) {
    const [upcomingBirthdaysList, setUpcomingBirthdaysList] = useState(null);
    useEffect(() => {
        if (employees) {
            var now = moment(new Date());
            const filterData = employees.filter((item) => {
                if (item.dob) {
                    console.log("EMPLOYEE", item);
                    const birthDay = moment(item.dob).year(now.year());
                    var birthDayNextYear = moment(item.dob).year(now.year() + 1);
                    var daysRemaining = Math.min((birthDay.diff(now, 'days')), (birthDayNextYear.diff(now, 'days')));
                    if ((daysRemaining >= 0) && (daysRemaining <= 60)) {
                        return item
                    }

                }
            })
            setUpcomingBirthdaysList(filterData)
        }
    }, [employees])
    console.log("upcomingBirthdaysList -", upcomingBirthdaysList);
    console.log("employees-", employees);
    return (
        <ul>
            {upcomingBirthdaysList && upcomingBirthdaysList.map((item) => {
                return (
                    <li>
                        <div className="flex">
                            <div className="title">
                                <p className="name"><strong>{item.name}</strong></p>
                                <p className="name">{moment(item.dob).format("DD-MMM")}</p>
                            </div>
                        </div>
                    </li>
                )
            })}

        </ul>

    )
}

export default UpcomingBirthdays
