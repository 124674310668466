import React, { useEffect } from "react";
import DataTable from "../common/DataTable";
import Pagination from "../common/Pagination";
import {
  view_all_table,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/jobposts_enum";
import {
  useAllJobpostsByCondition,
  useAllJobpostsStats,
} from "../../shared/hooks/UseJobpost";
import ExportComponent from "../common/ExportComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";

function AllJobPostsComponent({ label, value }) {
  const [data, setPageNumber, setParams, deleteBtnClicked] =
    useAllJobpostsByCondition();
  const [statsdata, setParamss] = useAllJobpostsStats();

  const {
    loading,
    jobposts,
    total_jobposts,
    page,
    pages,
    jobposts_stats_loading,
    jobposts_stats,
  } = data;

  useEffect(() => {
    if (value) {
      setParams([{ label, value }]);
      setParamss([{ label, value }]);
    }

    // setParamsCandidate([{ label: "client_name", value: match.params.id }]);
  }, [value]);

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (jobposts) {
      convertToReadable(jobposts, inputFields);
    }
  }, [jobposts]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Requirements", "requirements");
  };

  return (
    <>
      <ExportComponent handleOnExport={handleOnExport} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div
              className="card"
              style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
            >
              <div className="card-header">
                <div className="card-title">Requirements</div>
              </div>
              <div className="card-body">
                {total_jobposts} records found
                {/* {jobposts_stats &&
                    jobposts_stats.map((item) => {
                      if (item._id) {
                        return (
                          <div className="col-md-2">
                            <div className="card">
                              <div className="card-body">
                                <div>{item._id}</div>
                                <div> {item.count} </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })} */}
                <div>
                  <DataTable
                    keys={view_all_table}
                    data={jobposts}
                    field={LINK_URL}
                    page={page}
                    deleteBtnClicked={deleteBtnClicked}
                    loading={loading}
                    inputFields={inputFields}
                    PAGE_TITLE={PAGE_TITLE}
                    PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    hideEditBtn={true}
                    hideDeleteBtn={true}
                    hideQVBtn={true}
                  />
                  <Pagination
                    data={jobposts}
                    page={page}
                    pages={pages}
                    count={total_jobposts}
                    setPage={setPageNumber}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllJobPostsComponent;
