export const GET_DASHBOARDS_STATED = "GET_DASHBOARDS_STATED";
export const GET_DASHBOARDS = "GET_DASHBOARDS";
export const GET_DASHBOARDS_ENDED = "GET_DASHBOARDS_ENDED";
export const ADD_DASHBOARD_STATED = "ADD_DASHBOARD_STARTED";
export const ADD_DASHBOARD = "ADD_DASHBOARD";
export const ADD_DASHBOARD_ENDED = "ADD_DASHBOARD_ENDED";
export const EDIT_DASHBOARD_STATED = "EDIT_DASHBOARD_STATED";
export const EDIT_DASHBOARD = "EDIT_DASHBOARD";
export const EDIT_DASHBOARD_ENDED = "EDIT_DASHBOARD_ENDED";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_STATED = "GET_DASHBOARD_STATED";
export const GET_DASHBOARD_ENDED = "GET_DASHBOARD_ENDED";
export const RESET_DASHBOARD = "RESET_DASHBOARD";
export const ERROR_DASHBOARD = "ERROR_DASHBOARD";
export const GET_ALL_DASHBOARDS_STATED = "GET_ALL_DASHBOARDS_STATED";
export const GET_ALL_DASHBOARDS = "GET_ALL_DASHBOARDS";
export const GET_ALL_DASHBOARDS_ENDED = "GET_ALL_DASHBOARDS_ENDED";
