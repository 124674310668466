export const GET_INTERVIEWS = "GET_INTERVIEWS";
export const ADD_INTERVIEW = "ADD_INTERVIEW";
export const EDIT_INTERVIEW = "EDIT_INTERVIEW";
export const GET_INTERVIEW = "GET_INTERVIEW";
export const RESET_INTERVIEW = "RESET_INTERVIEW";
export const ERROR_INTERVIEW = "ERROR_INTERVIEW";
export const GET_ALL_INTERVIEWS = "GET_ALL_INTERVIEWS";
export const GET_ALL_INTERVIEWS_BY_JOB_POST = "GET_ALL_INTERVIEWS_BY_JOB_POST";

export const INTERVIEW_STATS_STARTED = "INTERVIEW_STATS_STARTED";
export const INTERVIEW_STATS = "INTERVIEW_STATS";
export const INTERVIEW_STATS_ENDED = "INTERVIEW_STATS_ENDED";