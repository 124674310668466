import moment from "moment";

export const PAGE_TITLE = "Attendances";
export const PAGE_SINGLE_TITLE = "Todays Attendance";
export const LINK_URL = "attendances";
export const SEARCH_TERMS = ["name", "meta_title"];
export const inputFields = {
    employee: {
        type: "related",
        required: false,
        title: "Employee",
        field: "name",
    },
    check_in_time: {
        type: "string",
        required: false,
        title: "CheckIn Time",
        inputType: "date",
        inputType: "datetime-local",
    },
    check_out_time: {
        type: "string",
        required: false,
        title: "CheckOut Time",
        inputType: "date",
        inputType: "datetime-local",
    },

};
export const initialValues = {
    employee: "",
    check_in_time: moment().format("YYYY-MM-DD"),
    check_out_time: moment().format("YYYY-MM-DD"),

};

export const view_all_table = [
    {
        name: "Employee",
        value: "employee",
        related: true,
        field: "name",
        is_link: true,
        link_field: "employees",
    },
    { name: "Name", value: "name" },
    { name: "Slug", value: "slug" },
];

export const SIDEBAR_OPTIONS = [
    {
        id: "employee",
        field: "employee",
        label: "Employee",
        type: "related",
        search_type: "exact",
        inputType: "text",
        condition: "",
    },
    {
        id: "dateFrom",
        field: "createdAt",
        label: "Date From",
        type: "string",
        search_type: "conditional",
        inputType: "date",
        condition: "$gt",
    },
    {
        id: "dateTo",
        field: "createdAt",
        label: "Date To",
        type: "string",
        search_type: "conditional",
        inputType: "date",
        condition: "$lt",
    },
]
