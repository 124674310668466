import api from "../../domain/api";
import {
  GET_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  GET_CLIENT,
  RESET_CLIENT,
  GET_ALL_CLIENTS,
  ERROR_CLIENT,
  CLIENT_STATS_STARTED,
  CLIENT_STATS,
  CLIENT_STATS_ENDED,
  GET_ALL_CLIENTS_STATED,
  GET_ALL_CLIENTS_ENDED,
} from "../types/client_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addClient = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/clients`, formData);
    dispatch({
      type: ADD_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getClients =
  ({ pageNumber = "", params }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: RESET_CLIENT,
        });
        // console.log("queryParams", queryParams);
        const queryParams = qs.parse(window.location.search.replace("?", ""));
        const query = qs.stringify(queryParams, {
          encodeValuesOnly: true, // prettify url
        });
        let URL_TO_FETCH = "";
        if (params) {
          let search_params = "";
          params.map((item) => {
            search_params += `&exact[${item.label}]=${item.value}`;
          });
          URL_TO_FETCH = `/clients?&${search_params}&${query}`;
        } else {
          URL_TO_FETCH = `/clients?&${query}`;
        }

        const { data } = await api.get(
          URL_TO_FETCH
        );

        dispatch({
          type: GET_CLIENTS,
          payload: data,
        });
      } catch (error) {
        console.log(error);
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };

export const getClientsStats =
  ({ params }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: CLIENT_STATS_STARTED,
        });
        const queryParams = qs.parse(window.location.search.replace("?", ""));

        const query = qs.stringify(queryParams, {
          encodeValuesOnly: true, // prettify url
        });
        let URL_TO_FETCH = "";
        if (params) {
          let search_params = "";
          params.map((item) => {
            search_params += `&exact[${item.label}]=${item.value}`;
          });
          URL_TO_FETCH = `/clients/stats/?${search_params}&${query}`;
        } else {
          URL_TO_FETCH = `/clients/stats/?${query}`;
        }

        const { data } = await api.get(URL_TO_FETCH);

        dispatch({
          type: CLIENT_STATS,
          payload: data,
        });
        dispatch({
          type: CLIENT_STATS_ENDED,
        });
      } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };
export const getClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CLIENT,
    });
    const { data } = await api.get(`/clients/${id}`);

    dispatch({
      type: GET_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editClient = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/clients/${id}`, formData);
    dispatch({
      type: EDIT_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteClient = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/clients/${id}`);
    dispatch(setAlert("Client Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllClients =
  ({ term, value }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: GET_ALL_CLIENTS_STATED,
        });
        const { data } = await api.get(
          `/clients/all?term=${term}&value=${value}`
        );

        dispatch({
          type: GET_ALL_CLIENTS,
          payload: data,
        });
        dispatch({
          type: GET_ALL_CLIENTS_ENDED,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_CLIENTS_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };
export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_CLIENT });
};
