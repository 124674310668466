import {
  GET_CANDIDATES,
  ADD_CANDIDATE,
  EDIT_CANDIDATE,
  GET_CANDIDATE,
  RESET_CANDIDATE,
  GET_ALL_CANDIDATES,
  ERROR_CANDIDATE,
  INTERVIEWS_BY_CANDIDATE,
  CANDIDATE_STATS_STARTED,
  CANDIDATE_STATS,
  CANDIDATE_STATS_ENDED,
} from "../types/candidate_type";

const initialState = {
  candidates: null,
  page: null,
  pages: null,
  candidate: null,
  total_candidates: 0,
  loading: true,
  error: {},
  candidate_message: null,
  all_candidates: null,
  interviews_by_candidate: null,
  candidates_stats_loading: true,
  candidates_stats: null,
};

export const candidate_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CANDIDATES:
      return {
        ...state,
        candidates: payload.candidates,
        pages: payload.pages,
        page: payload.page,
        total_candidates: payload.count,
        loading: false,
        error: {},
      };

    case CANDIDATE_STATS_STARTED:
      return {
        ...state,
        candidates_stats: null,
        candidates_stats_loading: true,
      };
    case CANDIDATE_STATS:
      return {
        ...state,
        candidates_stats: payload,
      };
    case CANDIDATE_STATS_ENDED:
      return {
        ...state,
        candidates_stats_loading: false,
      };
    case GET_ALL_CANDIDATES:
      return {
        ...state,
        all_candidates: payload,
        loading: false,
        error: {},
      };

    case INTERVIEWS_BY_CANDIDATE:
      return {
        ...state,
        interviews_by_candidate: payload.interviews,
        loading: false,
        error: {},
      };

    case RESET_CANDIDATE:
      return {
        ...state,
        candidate: null,
        loading: true,
        error: {},
      };

    case ADD_CANDIDATE:
      return {
        ...state,
        candidate_message: payload,
        loading: false,
        error: {},
      };
    case GET_CANDIDATE:
      return {
        ...state,
        candidate: payload,
        loading: false,
        error: {},
      };
    case EDIT_CANDIDATE:
      return {
        ...state,
        candidate_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
