import React, { useState, useEffect } from 'react'
import Header from "../../components/template/Header";
import moment from "moment";
import { useHistory, useLocation } from "react-router";
import {
    SEARCH_TERMS,
    view_all_table,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
    SIDEBAR_OPTIONS,
    inputFields,
} from "../../shared/enums/attendances_enum";
import SidebarFilter from "../../components/common/SidebarFilter";
import {
    useAllAttendances,
    useGetDropdownOptions,

} from "../../shared/hooks/UseAttendance";
import ExportComponent from '../../components/common/ExportComponent';
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";
import { useAllEmployees } from '../../shared/hooks/UseEmployee';




function AttendanceByEmployee() {

    const [data] = useAllAttendances();
    const { attendances } = data;

    const [exportXLSXData] = UseFilter();

    const [convertToReadable, exportData] = UseDataForExcel();

    useEffect(() => {
        if (attendances) {
            convertToReadable(attendances, inputFields);
        }
    }, [attendances]);

    const handleOnExport = () => {
        exportXLSXData(exportData, "Attendances", "attendances");
    };

    const [
        dropdownOptions,
        setEmployeeSearchField,
        setEmployeeSearchValue,

    ] = useGetDropdownOptions();

    const loadOptions = async (inputValue, callback, field) => {
        if (field == "employee") {
            await setEmployeeSearchField("name");
            await setEmployeeSearchValue(inputValue);
            callback(dropdownOptions.employee);
        }
    };
    const history = useHistory();
    const location = useLocation();
    const [selectedFields, setSelectedFields] = useState({});



    return (
        <div>
            <Header />

            <div style={{ margin: "0 ", marginTop: "150px" }}>
                <ExportComponent handleOnExport={handleOnExport} />
                <section>
                    <div className="container-fluid">
                        <div className="row">

                            {SIDEBAR_OPTIONS && (
                                <SidebarFilter
                                    SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                                    dropdown_options={dropdownOptions}
                                    loadOptions={loadOptions}

                                />
                            )}
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <p>
                                                <strong>Attendance By Employee</strong>
                                            </p>

                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-stripped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th> # </th>
                                                    <th> Date </th>
                                                    <th> Employee </th>
                                                    <th> Check In Time </th>
                                                    <th> Check Out Time </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {attendances && attendances.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td> {item.createdAt && moment(item.createdAt).format("DD-MM-YYYY")}  </td>
                                                            <td>{item.employee && item.employee.name}</td>
                                                            <td>
                                                                {item.check_in_time ? moment(item.check_in_time).format("hh:mm A") : "-"}
                                                            </td>
                                                            <td>
                                                                {item.check_out_time ? moment(item.check_out_time).format("hh:mm A") : "-"}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AttendanceByEmployee