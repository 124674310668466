import React, { useEffect } from 'react';
import ExportComponent from '../../components/common/ExportComponent';
import { inputFields } from '../../shared/enums/candidates_enum';
import { UseDataForExcel, UseFilter } from '../../shared/hooks/UseExcel';

import { useSelectAllCandidate } from '../../shared/hooks/UseCandidate';

export default function ExportCandidates() {
  const [candidateData, setTerm, setValue] = useSelectAllCandidate();

  const [exportXLSXData] = UseFilter();
  const { all_candidates } = candidateData;
  const [convertToReadable, exportData] = UseDataForExcel();

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Candidates', 'Candidates');
  };

  useEffect(() => {
    if (all_candidates) {
      convertToReadable(all_candidates, inputFields);
    }
  }, [all_candidates]);
  useEffect(() => {
    setValue('Candidate Joined');
    setTerm('candidate_status');
  }, []);
  return (
    <>
      <ExportComponent
        label={'Export Candidates'}
        handleOnExport={handleOnExport}
      />
    </>
  );
}
