import {
  GET_DAILY_REPORTS_STARTED,
  GET_DAILY_REPORTS,
  GET_DAILY_REPORTS_ENDED,
  GET_CLOSED_REPORTS_STARTED,
  GET_CLOSED_REPORTS,
  GET_CLOSED_REPORTS_ENDED,
} from "../types/report_type";

const initialState = {
  daily_reports_loading: true,
  daily_reports: null,

  closed_reports_loading: true,
  closed_reports: null,
};

export const report_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DAILY_REPORTS_STARTED:
      return {
        ...state,
        daily_reports_loading: true,
      };
    case GET_DAILY_REPORTS:
      return {
        ...state,
        daily_reports: payload,
      };
    case GET_DAILY_REPORTS_ENDED:
      return {
        ...state,
        daily_reports_loading: false,
      };
    case GET_CLOSED_REPORTS_STARTED:
      return {
        ...state,
        closed_reports_loading: true,
      };
    case GET_CLOSED_REPORTS:
      return {
        ...state,
        closed_reports: payload,
      };
    case GET_CLOSED_REPORTS_ENDED:
      return {
        ...state,
        closed_reports_loading: false,
      };

    default:
      return state;
  }
};
