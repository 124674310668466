export const GET_CLOSEDCANDIDATES_STATED = "GET_CLOSEDCANDIDATES_STATED";
export const GET_CLOSEDCANDIDATES = "GET_CLOSEDCANDIDATES";
export const GET_CLOSEDCANDIDATES_ENDED = "GET_CLOSEDCANDIDATES_ENDED";
export const ADD_CLOSEDCANDIDATE_STATED = "ADD_CLOSEDCANDIDATE_STARTED";
export const ADD_CLOSEDCANDIDATE = "ADD_CLOSEDCANDIDATE";
export const ADD_CLOSEDCANDIDATE_ENDED = "ADD_CLOSEDCANDIDATE_ENDED";
export const EDIT_CLOSEDCANDIDATE_STATED = "EDIT_CLOSEDCANDIDATE_STATED";
export const EDIT_CLOSEDCANDIDATE = "EDIT_CLOSEDCANDIDATE";
export const EDIT_CLOSEDCANDIDATE_ENDED = "EDIT_CLOSEDCANDIDATE_ENDED";
export const GET_CLOSEDCANDIDATE = "GET_CLOSEDCANDIDATE";
export const GET_CLOSEDCANDIDATE_STATED = "GET_CLOSEDCANDIDATE_STATED";
export const GET_CLOSEDCANDIDATE_ENDED = "GET_CLOSEDCANDIDATE_ENDED";
export const RESET_CLOSEDCANDIDATE = "RESET_CLOSEDCANDIDATE";
export const ERROR_CLOSEDCANDIDATE = "ERROR_CLOSEDCANDIDATE";
export const GET_ALL_CLOSEDCANDIDATES_STATED = "GET_ALL_CLOSEDCANDIDATES_STATED";
export const GET_ALL_CLOSEDCANDIDATES = "GET_ALL_CLOSEDCANDIDATES";
export const GET_ALL_CLOSEDCANDIDATES_ENDED = "GET_ALL_CLOSEDCANDIDATES_ENDED";
