import moment from "moment";
export const PAGE_TITLE = "Contacts";
export const PAGE_SINGLE_TITLE = "Contact";
export const LINK_URL = "Contacts";
export const SEARCH_TERMS = ["first_name", "meta_title"];
export const inputFields = {
    title: {
        type: "select",
        required: false,
        title: "Title",
        options: ["Mr", "Mrs.", "Ms.",]
    },
    first_name: {
        type: "string",
        required: true,
        title: "First Name",
    },
    last_name: {
        type: "string",
        required: true,
        title: "Last Name",
    },
    department: {
        type: "string",
        required: false,
        title: "Department",
    },
    fax: {
        type: "string",
        required: false,
        title: "FAX",
    },
    client_name: {
        type: "related",
        required: true,
        title: "Client Name",
        field: "client_name"
    },
    email: {
        type: "string",
        required: false,
        title: "Email",
    },
    secondary_email: {
        type: "string",
        required: false,
        title: "Secondary Email",
    },
    job_title: {
        type: "string",
        required: false,
        title: "Job Title",
    },
    work_phone: {
        type: "string",
        required: false,
        title: "Work Phone",
    },
    mobile: {
        type: "string",
        required: false,
        title: "Mobile",
    },
    skype_id: {
        type: "string",
        required: false,
        title: "Skype ID",
    },
    twitter: {
        type: "string",
        required: false,
        title: "Twitter",
    },
    address_info_divider: {
        type: "divider",
        title: "Address Information",
    },
    mailing_street: {
        type: "string",
        required: false,
        title: "Mailing Street",
    },
    mailing_city: {
        type: "string",
        required: false,
        title: "Mailing City",
    },
    mailing_state: {
        type: "string",
        required: false,
        title: "Mailing State",
    },
    mailing_zip: {
        type: "string",
        required: false,
        title: "Mailing Zip",
    },
    mailing_country: {
        type: "string",
        required: false,
        title: "Mailing Country",
    },
    other_street: {
        type: "string",
        required: false,
        title: "Other Street",
    },
    other_city: {
        type: "string",
        required: false,
        title: "Other City",
    },
    other_state: {
        type: "string",
        required: false,
        title: "Other State",
    },
    other_zip: {
        type: "string",
        required: false,
        title: "Other Zip",
    },
    other_country: {
        type: "string",
        required: false,
        title: "Other Country",
    },
    other_info_divider: {
        type: "divider",
        title: "Other Information",
    },
    source: {
        type: "related",
        required: false,
        title: "Source",
        static: true,

    },
    contact_owner: {
        type: "related",
        required: false,
        title: "Contact Owner",
        field: "name"
    },
    is_primary_contact: {
        type: "checkbox",
        required: false,
        title: "Is Primary Contact",
    },
    email_opt_out: {
        type: "checkbox",
        required: false,
        title: "Email Opt Out",
    },
    description_information: {
        type: "divider",
        required: false,
        title: "Description Information",
    },
    description: {
        type: "text",
        required: false,
        title: "Description",
    },
    attachment_info: {
        type: "file",
        required: false,
        title: "Attachment Info",
    },
    other: {
        type: "file",
        required: false,
        title: "Other",
    },
};
export const initialValues = {
    title: "",
    first_name: "",
    last_name: "",
    department: "",
    fax: "",
    client_name: "",
    email: "",
    secondary_email: "",
    job_title: "",
    work_phone: "",
    mobile: "",
    skype_id: "",
    twitter: "",
    mailing_street: "",
    mailing_city: "",
    mailing_state: "",
    mailing_zip: "",
    mailing_country: "",
    other_street: "",
    other_city: "",
    other_state: "",
    other_zip: "",
    other_country: "",
    source: "",
    contact_owner: "",
    is_primary_contact: false,
    email_opt_out: false,
    description_information: "",
    description: "",
    attachment_info: "",
    other: "",
};

export const view_all_table = [
    { name: "First Name", value: "first_name" },
    { name: "Email", value: "email" },
    { name: "Client Name", value: "client_name", related: true, field: "client_name" },
    { name: "Job Title", value: "job_title" },
];

export const SIDEBAR_OPTIONS = [

    {
        id: "first_name",
        field: "first_name",
        label: "First Name",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
    {
        id: "last_name",
        field: "last_name",
        label: "Last Name",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
    {
        id: "client_name",
        field: "client_name",
        label: "Client Name",
        type: "related",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
    {
        id: "email",
        field: "email",
        label: "Email",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },

    {
        id: "job_title",
        field: "job_title",
        label: "Job Title",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },

    {
        id: "mobile",
        field: "mobile",
        label: "Mobile",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
    {
        id: "source",
        field: "source",
        label: "Source",
        type: "related",
        search_type: "exact",
        inputType: "text",
        condition: "",

    },


]

