export const GET_HOLIDAYS_STATED = "GET_HOLIDAYS_STATED";
export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const GET_HOLIDAYS_ENDED = "GET_HOLIDAYS_ENDED";
export const ADD_HOLIDAY_STATED = "ADD_HOLIDAY_STARTED";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const ADD_HOLIDAY_ENDED = "ADD_HOLIDAY_ENDED";
export const EDIT_HOLIDAY_STATED = "EDIT_HOLIDAY_STATED";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const EDIT_HOLIDAY_ENDED = "EDIT_HOLIDAY_ENDED";
export const GET_HOLIDAY = "GET_HOLIDAY";
export const GET_HOLIDAY_STATED = "GET_HOLIDAY_STATED";
export const GET_HOLIDAY_ENDED = "GET_HOLIDAY_ENDED";
export const RESET_HOLIDAY = "RESET_HOLIDAY";
export const ERROR_HOLIDAY = "ERROR_HOLIDAY";
export const GET_ALL_HOLIDAYS_STATED = "GET_ALL_HOLIDAYS_STATED";
export const GET_ALL_HOLIDAYS = "GET_ALL_HOLIDAYS";
export const GET_ALL_HOLIDAYS_ENDED = "GET_ALL_HOLIDAYS_ENDED";
