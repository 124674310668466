import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";

import { upload } from "../../shared/upload";
import {
    initialValues,
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
} from "../../shared/enums/contacts_enum";
import { useEffect } from "react";
import { useCreateContact, useAllContacts, useGetDropdownOptions } from "../../shared/hooks/UseContact";

const AddContact = ({ }) => {
    let history = useHistory();
    const [addData] = useCreateContact();
    const [featuredImage, setFeaturedImage] = useState(null);
    const [gallery, setGallery] = useState(null);

    const submitFormClicked = async (values) => {
        const data = await convertToFormData({ values, featuredImage });
        console.log("DATA", data);
        await addData(data);
        history.goBack();
    };

    const [dropdownOptions] = useGetDropdownOptions();

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={`Add ${PAGE_SINGLE_TITLE}`}
                    mainLinkTitle={PAGE_TITLE}
                    mainLinkUrl={LINK_URL}
                    activeLink="Add"
                />
            </div>
            <div className="container-fluid">
                <div className="col-lg-9">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
                            <p className="card-title-desc">
                                Enter Details to add {PAGE_SINGLE_TITLE}
                            </p>
                        </div>
                        <AddForm
                            edit={false}
                            featuredImage={featuredImage}
                            gallery={gallery}
                            setFeaturedImage={setFeaturedImage}
                            setGallery={setGallery}
                            submitForm={submitFormClicked}
                            inputFields={inputFields}
                            initialValues={initialValues}
                            dropdown_options={dropdownOptions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddContact;
