import {
  GET_CVS,
  ADD_CV,
  EDIT_CV,
  GET_CV,
  RESET_CV,
  GET_ALL_CVS,
  ERROR_CV,
} from "../types/cv_type";

const initialState = {
  cvs: null,
  page: null,
  pages: null,
  cv: null,
  total_cvs: 0,
  loading: true,
  error: {},
  cv_message: null,
  all_cvs: null,
};

export const cv_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CVS:
      return {
        ...state,
        cvs: payload.cvs,
        pages: payload.pages,
        page: payload.page,
        total_cvs: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_CVS:
      return {
        ...state,
        all_cvs: payload,
        loading: false,
        error: {},
      };

    case RESET_CV:
      return {
        ...state,
        cv: null,
        loading: true,
        error: {},
      };

    case ADD_CV:
      return {
        ...state,
        cv_message: payload,
        loading: false,
        error: {},
      };
    case GET_CV:
      return {
        ...state,
        cv: payload,
        loading: false,
        error: {},
      };
    case EDIT_CV:
      return {
        ...state,
        cv_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_CV:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
                