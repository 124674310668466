import moment from 'moment';
import React, { useState, useEffect } from 'react'

function UpcomingJobanniversarys({ employees }) {
    const [upcomingJobAnniversarysList, setUpcomingJobAnniversarysList] = useState(null);
    useEffect(() => {
        if (employees) {
            var now = moment(new Date());
            const filterData = employees.filter((item) => {
                if (item.doj) {
                    console.log("EMPLOYEE", item);
                    const jobAnniversary = moment(item.doj).year(now.year());
                    var jobAnniversaryNextYear = moment(item.doj).year(now.year() + 1);
                    var daysRemaining = Math.min((jobAnniversary.diff(now, 'days')), (jobAnniversaryNextYear.diff(now, 'days')));
                    if ((daysRemaining >= 0) && (daysRemaining <= 60)) {
                        return item
                    }
                }
            })
            setUpcomingJobAnniversarysList(filterData)
        }
    }, [employees])
    return (
        <ul>
            {upcomingJobAnniversarysList && upcomingJobAnniversarysList.map((item) => {
                return (
                    <li>
                        <div className="flex">
                            <div className="title">
                                <p className="name"><strong>{item.name}</strong></p>
                                <p className="name">{moment(item.doj).format("DD-MMM")}</p>
                            </div>
                        </div>
                    </li>
                )
            })}

        </ul>

    )
}

export default UpcomingJobanniversarys