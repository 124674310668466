import React, { useEffect } from 'react';
import './assets/css/bootstrap.min.css';
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/wysiwyg.css';
import './assets/css/responsive.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Login from './containers/login/Login';
import { loadUser } from './store/actions/auth';
import setAuthToken from './domain/setAuthToken';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import Dashboard from './containers/dashboard/Dashboard';
import PageNotFound from './containers/notfound/PageNotFound';

import Profile from './containers/profile/Profile';

import AddClient from './containers/clients/AddClient';
import AllClients from './containers/clients/AllClients';
import ViewClient from './containers/clients/ViewClient';
import EditClient from './containers/clients/EditClient';
import AddJobpost from './containers/jobposts/AddJobpost';
import AllJobposts from './containers/jobposts/AllJobposts';
import ViewJobpost from './containers/jobposts/ViewJobpost';
import EditJobpost from './containers/jobposts/EditJobpost';
import AddUserrole from './containers/userroles/AddUserrole';
import AllUserroles from './containers/userroles/AllUserroles';
import ViewUserrole from './containers/userroles/ViewUserrole';
import EditUserrole from './containers/userroles/EditUserrole';
import AddApplicant from './containers/applicants/AddApplicant';
import AllApplicants from './containers/applicants/AllApplicants';
import ViewApplicant from './containers/applicants/ViewApplicant';
import EditApplicant from './containers/applicants/EditApplicant';
import AddEmployee from './containers/employees/AddEmployee';
import AllEmployees from './containers/employees/AllEmployees';
import ViewEmployee from './containers/employees/ViewEmployee';
import EditEmployee from './containers/employees/EditEmployee';
import AddCv from './containers/cvs/AddCv';
import AllCvs from './containers/cvs/AllCvs';
import ViewCv from './containers/cvs/ViewCv';
import EditCv from './containers/cvs/EditCv';
import AddCandidate from './containers/candidates/AddCandidate';
import AllCandidates from './containers/candidates/AllCandidates';
import ViewCandidate from './containers/candidates/ViewCandidate';
import EditCandidate from './containers/candidates/EditCandidate';
import AddSkillset from './containers/skillsets/AddSkillset';
import AllSkillsets from './containers/skillsets/AllSkillsets';
import ViewSkillset from './containers/skillsets/ViewSkillset';
import EditSkillset from './containers/skillsets/EditSkillset';
import AddSource from './containers/sources/AddSource';
import AllSources from './containers/sources/AllSources';
import ViewSource from './containers/sources/ViewSource';
import EditSource from './containers/sources/EditSource';
import AddIndustry from './containers/industrys/AddIndustry';
import AllIndustrys from './containers/industrys/AllIndustrys';
import ViewIndustry from './containers/industrys/ViewIndustry';
import EditIndustry from './containers/industrys/EditIndustry';
import AddCandidatestatus from './containers/candidatestatus/AddCandidatestatus';
import AllCandidatestatuss from './containers/candidatestatus/AllCandidatestatuss';
import ViewCandidatestatus from './containers/candidatestatus/ViewCandidatestatus';
import EditCandidatestatus from './containers/candidatestatus/EditCandidatestatus';
import AddContact from './containers/contacts/AddContact';
import AllContacts from './containers/contacts/AllContacts';
import ViewContact from './containers/contacts/ViewContact';
import EditContact from './containers/contacts/EditContact';
import AddInterview from './containers/interviews/AddInterview';
import AllInterviews from './containers/interviews/AllInterviews';
import ViewInterview from './containers/interviews/ViewInterview';
import EditInterview from './containers/interviews/EditInterview';
// import ViewNotification from "./containers/notifications/ViewNotification";
import AddAdmin from './containers/admins/AddAdmin';
import AllAdmins from './containers/admins/AllAdmins';
import ViewAdmin from './containers/admins/ViewAdmin';
import EditAdmin from './containers/admins/EditAdmin';
import TodayAttendance from './containers/attendances/TodaysAttendance';
import ViewAttendance from './containers/attendances/ViewAttendance';

import CVImport from './containers/candidates/CvImport';
import AddClosedcandidate from './containers/closedcandidates/AddClosedcandidate';
import AllClosedcandidates from './containers/closedcandidates/AllClosedcandidates';
import ViewClosedcandidate from './containers/closedcandidates/ViewClosedcandidate';
import EditClosedcandidate from './containers/closedcandidates/EditClosedcandidate';

import AddHoliday from './containers/holidays/AddHoliday';
import AllHolidays from './containers/holidays/AllHolidays';
import ViewHoliday from './containers/holidays/ViewHoliday';
import EditHoliday from './containers/holidays/EditHoliday';
import AttendaceByDate from './containers/attendances/AttendaceByDate';
import AttendanceByEmployee from './containers/attendances/AttendanceByEmployee';
import EmployeeReport from './containers/employees/EmployeeReport';
import ClosedByRecruiter from './containers/reports/ClosedByRecruiter';
import EntryByRecruiter from './containers/reports/EntryByRecruiter';
import JobPostCandidate from './containers/reports/JobPostCandidatesReport';

// import setAuthToken from "./domain/setAuthToken";

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path='/' component={Login} />

          <PrivateRoutes exact path='/dashboard' component={Dashboard} />
          <PrivateRoutes exact path='/profile' component={Profile} />

          <PrivateRoutes exact path='/clients' component={AllClients} />
          <PrivateRoutes exact path='/clients/add' component={AddClient} />
          <PrivateRoutes
            exact
            path='/clients/:id/view'
            component={ViewClient}
          />
          <PrivateRoutes
            exact
            path='/clients/:id/edit'
            component={EditClient}
          />
          <PrivateRoutes exact path='/jobposts' component={AllJobposts} />
          <PrivateRoutes exact path='/jobposts/add' component={AddJobpost} />
          <PrivateRoutes
            exact
            path='/jobposts/:id/view'
            component={ViewJobpost}
          />
          <PrivateRoutes
            exact
            path='/jobposts/:id/edit'
            component={EditJobpost}
          />
          <PrivateRoutes exact path='/userroles' component={AllUserroles} />
          <PrivateRoutes exact path='/userroles/add' component={AddUserrole} />
          <PrivateRoutes
            exact
            path='/userroles/:id/view'
            component={ViewUserrole}
          />
          <PrivateRoutes
            exact
            path='/userroles/:id/edit'
            component={EditUserrole}
          />
          <PrivateRoutes exact path='/appliacnts' component={AllApplicants} />
          <PrivateRoutes
            exact
            path='/appliacnts/add'
            component={AddApplicant}
          />
          <PrivateRoutes
            exact
            path='/appliacnts/:id/view'
            component={ViewApplicant}
          />
          <PrivateRoutes
            exact
            path='/appliacnts/:id/edit'
            component={EditApplicant}
          />
          <PrivateRoutes exact path='/employees' component={AllEmployees} />
          <PrivateRoutes exact path='/employees/add' component={AddEmployee} />
          <PrivateRoutes
            exact
            path='/employees/:id/view'
            component={ViewEmployee}
          />
          <PrivateRoutes
            exact
            path='/employees/:id/edit'
            component={EditEmployee}
          />
          <PrivateRoutes
            exact
            path='/employeereports'
            component={EmployeeReport}
          />
          <PrivateRoutes exact path='/cvs' component={AllCvs} />
          <PrivateRoutes exact path='/cvs/add' component={AddCv} />
          <PrivateRoutes exact path='/cvs/:id/view' component={ViewCv} />
          <PrivateRoutes exact path='/cvs/:id/edit' component={EditCv} />
          <PrivateRoutes exact path='/candidates' component={AllCandidates} />
          <PrivateRoutes
            exact
            path='/candidates/add'
            component={AddCandidate}
          />
          <PrivateRoutes
            exact
            path='/candidates/cvimport'
            component={CVImport}
          />
          <PrivateRoutes
            exact
            path='/candidates/:id/view'
            component={ViewCandidate}
          />
          <PrivateRoutes
            exact
            path='/candidates/:id/edit'
            component={EditCandidate}
          />
          <PrivateRoutes exact path='/skillsets' component={AllSkillsets} />
          <PrivateRoutes exact path='/skillsets/add' component={AddSkillset} />
          <PrivateRoutes
            exact
            path='/skillsets/:id/view'
            component={ViewSkillset}
          />
          <PrivateRoutes
            exact
            path='/skillsets/:id/edit'
            component={EditSkillset}
          />
          <PrivateRoutes exact path='/sources' component={AllSources} />
          <PrivateRoutes exact path='/sources/add' component={AddSource} />
          <PrivateRoutes
            exact
            path='/sources/:id/view'
            component={ViewSource}
          />
          <PrivateRoutes
            exact
            path='/sources/:id/edit'
            component={EditSource}
          />
          <PrivateRoutes exact path='/industrys' component={AllIndustrys} />
          <PrivateRoutes exact path='/industrys/add' component={AddIndustry} />
          <PrivateRoutes
            exact
            path='/industrys/:id/view'
            component={ViewIndustry}
          />
          <PrivateRoutes
            exact
            path='/industrys/:id/edit'
            component={EditIndustry}
          />
          <PrivateRoutes
            exact
            path='/candidatestatus'
            component={AllCandidatestatuss}
          />
          <PrivateRoutes
            exact
            path='/candidatestatus/add'
            component={AddCandidatestatus}
          />
          <PrivateRoutes
            exact
            path='/candidatestatus/:id/view'
            component={ViewCandidatestatus}
          />
          <PrivateRoutes
            exact
            path='/candidatestatus/:id/edit'
            component={EditCandidatestatus}
          />
          <PrivateRoutes exact path='/contacts' component={AllContacts} />
          <PrivateRoutes exact path='/contacts/add' component={AddContact} />
          <PrivateRoutes
            exact
            path='/contacts/:id/view'
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path='/contacts/:id/edit'
            component={EditContact}
          />
          <PrivateRoutes exact path='/contacts' component={AllContacts} />
          <PrivateRoutes exact path='/contacts/add' component={AddContact} />
          <PrivateRoutes
            exact
            path='/contacts/:id/view'
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path='/contacts/:id/edit'
            component={EditContact}
          />
          <PrivateRoutes exact path='/interviews' component={AllInterviews} />
          <PrivateRoutes
            exact
            path='/interviews/add/:candidate'
            component={AddInterview}
          />
          <PrivateRoutes
            exact
            path='/interviews/:id/view'
            component={ViewInterview}
          />
          <PrivateRoutes
            exact
            path='/interviews/:id/edit'
            component={EditInterview}
          />

          <PrivateRoutes exact path='/attendances' component={ViewAttendance} />
          <PrivateRoutes exact path='/attendances' component={ViewAttendance} />
          <PrivateRoutes
            exact
            path='/attendances-by-date'
            component={AttendaceByDate}
          />
          <PrivateRoutes
            exact
            path='/attendances-by-employee'
            component={AttendanceByEmployee}
          />
          <PrivateRoutes
            exact
            path='/attendances/add'
            component={TodayAttendance}
          />
          <PrivateRoutes exact path='/admins' component={AllAdmins} />
          <PrivateRoutes exact path='/admins/add' component={AddAdmin} />
          <PrivateRoutes exact path='/admins/:id/view' component={ViewAdmin} />
          <PrivateRoutes exact path='/admins/:id/edit' component={EditAdmin} />

          <PrivateRoutes
            exact
            path='/closed-candidates'
            component={AllClosedcandidates}
          />
          <PrivateRoutes
            exact
            path='/closed-candidates/add'
            component={AddClosedcandidate}
          />
          <PrivateRoutes
            exact
            path='/closed-candidates/:id/view'
            component={ViewClosedcandidate}
          />
          <PrivateRoutes
            exact
            path='/closed-candidates/:id/edit'
            component={EditClosedcandidate}
          />

          <PrivateRoutes exact path='/holidays' component={AllHolidays} />
          <PrivateRoutes exact path='/holidays/add' component={AddHoliday} />
          <PrivateRoutes
            exact
            path='/holidays/:id/view'
            component={ViewHoliday}
          />
          <PrivateRoutes
            exact
            path='/holidays/:id/edit'
            component={EditHoliday}
          />
          <PrivateRoutes
            exact
            path='/reports/closed-by-recruiter'
            component={ClosedByRecruiter}
          />
          <PrivateRoutes
            exact
            path='/reports/entry-by-recruiter'
            component={EntryByRecruiter}
          />
          <PrivateRoutes
            exact
            path='/reports/job-post-candidates'
            component={JobPostCandidate}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
