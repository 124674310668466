import api from "../../domain/api";
import {
  GET_SKILLSETS,
  ADD_SKILLSET,
  EDIT_SKILLSET,
  GET_SKILLSET,
  RESET_SKILLSET,
  GET_ALL_SKILLSETS,
  ERROR_SKILLSET,
} from "../types/skillset_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addSkillset = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/skills`, formData);
    dispatch({
      type: ADD_SKILLSET,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getSkillsets =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_SKILLSET,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/skills?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_SKILLSETS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getSkillset = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SKILLSET,
    });
    const { data } = await api.get(`/skills/${id}`);

    dispatch({
      type: GET_SKILLSET,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editSkillset = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/skills/${id}`, formData);
    dispatch({
      type: EDIT_SKILLSET,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteSkillset = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/skills/${id}`);
    dispatch(setAlert("Skillset Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllSkillsets =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`/skills/all?term=${term}&value=${value}`);

      dispatch({
        type: GET_ALL_SKILLSETS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_SKILLSET });
};
