import React from "react";
import Header from "../../components/template/Header";
import moment from "moment";
import { useMonthlyAttendanceEmployee, useAllAttendances, useTodaysAttendanceEmployee, } from "../../shared/hooks/UseAttendance";

const ViewAttendance = () => {

  const [attendanceData] = useAllAttendances();
  console.log("AttendanceDATA -", attendanceData);

  const { total_attendance } = attendanceData;

  const [todaysData] = useTodaysAttendanceEmployee();

  const { today_attendance } = todaysData;
  return (
    <div>
      <Header />
      <div style={{ margin: "0 ", marginTop: "150px" }}>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">
                      <p>
                        <strong> Today Attendance </strong>
                      </p>
                      {moment().format("DD-MMM-YYYY")}
                    </div>
                  </div>



                  <div className="card-body">
                    <table className="table table-stripped table-bordered">
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Employee </th>
                          <th> Check In Time </th>
                          <th> Check Out Time </th>
                        </tr>
                      </thead>
                      <tbody>

                        {today_attendance && today_attendance.map((todayattendanceitem, index) => {
                          return (

                            <tr key={todayattendanceitem._id}>
                              <td> {index + 1} </td>
                              <td>{todayattendanceitem.employee && todayattendanceitem.employee.name}</td>
                              <td>
                                {todayattendanceitem.check_in_time
                                  ? moment(todayattendanceitem.check_in_time).format(
                                    "HH:mm:SS A"
                                  )
                                  : "-"}
                              </td>
                              <td>
                                {todayattendanceitem.check_out_time
                                  ? moment(todayattendanceitem.check_out_time).format(
                                    "HH:mm:SS A"
                                  )
                                  : "-"}
                              </td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewAttendance;
