import React, { useEffect } from 'react';
import Header from '../../components/template/Header';
import BreadCrumb from '../../components/template/BreadCrumb';
import {
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/employees_enum';
import { inputFields } from '../../shared/enums/candidates_enum';
import { inputFields as jobPostFields } from '../../shared/enums/jobposts_enum';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import ExportComponent from '../../components/common/ExportComponent';
import { UseDataForExcel, UseFilter } from '../../shared/hooks/UseExcel';
import { useCloseReport } from '../../shared/hooks/UseReport';
import { useAllJobpostsByCondition } from '../../shared/hooks/UseJobpost';
import { useAllCandidatesByCondition } from '../../shared/hooks/UseCandidate';
import ExportJobPost from './ExportJobPost';
import ExportCandidates from './ExportCandidate';

function ClosedByRecruiter({ match }) {
  const [reportData] = useCloseReport();
  const { closed_reports_loading, closed_reports } = reportData;

  return (
    <div>
      <div className='pace-done'>
        <div>
          <Header />
          <BreadCrumb
            title='Recruiters Report'
            mainLinkTitle='Recruiter'
            mainLinkUrl='/employees'
            activeLink={PAGE_TITLE}
          />
          <div>
            <ExportJobPost />
            <ExportCandidates />
            {/* <ExportComponent
              label={'Export Candidates'}
              handleOnExport={handleOnExport}
            /> */}

            {/* <ExportComponent
              label={'Export Requirements'}
              handleOnExport={handleOnExportJobPost}
            /> */}
          </div>
          <FilterDateComponent link={`/reports/closed-by-recruiter`} />

          <section className='employee-report-section'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-7'>
                  <div className='employee-reports-content'>
                    <div className='employee-reports-title'>
                      <p>Closed By Recruiters Report:</p>
                    </div>

                    <div className='employee-report-table'>
                      <table class='table table-bordered'>
                        <thead>
                          <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Recruiter</th>
                            <th scope='col'>Jobpost Closed By Recruiter</th>
                          </tr>
                        </thead>
                        <tbody>
                          {closed_reports &&
                            closed_reports.map((item, index) => {
                              return (
                                <tr>
                                  <th scope='row'>{index + 1}</th>
                                  <td>
                                    {item.recruiter && item.recruiter.name}
                                  </td>
                                  <td> {item.count} </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ClosedByRecruiter;
