import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData, upload } from "../../shared/upload";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/candidates_enum";
import { useEffect } from "react";
import {
  useCreateCandidate,
  useAllCandidates,
  useGetDropdownOptions,
} from "../../shared/hooks/UseCandidate";
import api from "../../domain/api";

const CVImport = ({ }) => {
  let history = useHistory();
  const [addData] = useCreateCandidate();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [cvFile, setCvFile] = useState(null);
  const [attachment, setAttachment] = useState(null);

  const [unformatedtext, setUnformatedtext] = useState(null)

  const submitFormClicked = async (values) => {
    values.cv_attachment = attachment;
    values.text_unformatted_resume = unformatedtext;
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  const [dropdownOptions] = useGetDropdownOptions();
  const [uploadedData, setUploadedData] = useState(null);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Upload CV</div>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{
                  name: "",
                }}
                validationSchema={Yup.object({
                  //   name: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  const formData = new FormData();
                  formData.append("cv_attachment", cvFile);
                  formData.append("values", JSON.stringify({}));

                  const { data } = await api.post(`/cvs/cv-import`, formData);
                  setAttachment(data.text);
                  var phoneRegex = /(?:[-+() ]*\d){10,13}/gm;
                  console.log(data);
                  setUnformatedtext(data.textParsed);
                  var phones =
                    data.textParsed.match(phoneRegex) &&
                    data.textParsed.match(phoneRegex).map(function (s) {
                      return s.trim();
                    });
                  var emailRegex =
                    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gm;

                  var emails =
                    data.textParsed.match(emailRegex) &&
                    data.textParsed.match(emailRegex).map(function (s) {
                      return s.trim();
                    });
                  //   console.log(res);

                  console.log("CV Phones", phones);
                  console.log("CVData", emails);
                  //   console.log("CVData", pincodes);
                  let searchData = {};
                  if (emails || phones) {
                    setUploadedData({
                      ...searchData,
                      ...{ text_unformatted_resume: data.textParsed },
                      ...{ email_id: emails && emails[0] ? emails[0] : "" },
                      ...{
                        alternate_email_1: emails && emails[1] ? emails[1] : "",
                      },
                      ...{
                        alternate_email_2: emails && emails[2] ? emails[2] : "",
                      },
                      ...{
                        alternate_email_3: emails && emails[3] ? emails[3] : "",
                      },
                      ...{ phone_number: phones && phones[0] ? phones[0] : "" },
                      ...{
                        alternate_phone_1: phones && phones[1] ? phones[1] : "",
                      },
                    });
                  }

                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  console.log(formik);
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="file"
                            onChange={(e) => setCvFile(e.target.files[0])}
                          />
                          <button className="btn btn-success"> Upload </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <div className="formatted-resume">
              {uploadedData && uploadedData.text_unformatted_resume}
            </div>

            <AddForm
              data={uploadedData}
              edit={true}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CVImport;
