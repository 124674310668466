import api from "../../domain/api";
import {
  GET_INDUSTRYS,
  ADD_INDUSTRY,
  EDIT_INDUSTRY,
  GET_INDUSTRY,
  RESET_INDUSTRY,
  GET_ALL_INDUSTRYS,
  ERROR_INDUSTRY,
} from "../types/industry_type";
import * as qs from "qs";

import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
export const addIndustry = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/industrys`, formData);
    dispatch({
      type: ADD_INDUSTRY,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getIndustrys =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_INDUSTRY,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      const { data } = await api.get(
        `/industrys?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_INDUSTRYS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getIndustry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_INDUSTRY,
    });
    const { data } = await api.get(`/industrys/${id}`);

    dispatch({
      type: GET_INDUSTRY,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editIndustry = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/industrys/${id}`, formData);
    dispatch({
      type: EDIT_INDUSTRY,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteIndustry = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/industrys/${id}`);
    dispatch(setAlert("Industry Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllIndustrys =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(
        `/industrys/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_INDUSTRYS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_INDUSTRY });
};
