import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/jobposts_enum";
import SingleView from "../../components/common/SingleView";
import {
  useSingleJobpost,
  useInterviewsByJobpost,
  useInterviewsByJobpostStats,
  useCandidatesByJobpost,
  useUpdateJobpost
} from "../../shared/hooks/UseJobpost";
import { useSelectAllInterviewByJobPosts } from "../../shared/hooks/UseInterview";
import { useSelectAllCandidatestatus } from "../../shared/hooks/UseCandidatestatus";
import ScheduleInterview from "./ScheduleInterview";
import { useAllInterviews } from "../../shared/hooks/UseInterview";
import { Link } from "react-router-dom";
import moment from "moment";
import { convertToFormData, upload } from "../../shared/upload";
// import AllJobPostsComponent from "../../components/jobposts/AllJobPostsComponent";
import AllCandidatesComponent from "../../components/candidates/AllCandidatesComponent";
import AllInterviewComponent from "../../components/interviews/AllInterviewsComponent";
import FilterDateComponent from "../../components/common/FilterDateComponent";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

const ViewJobpost = ({ match, field }) => {
  const [data] = useSingleJobpost(match.params.id);

  const [candidates_data] = useCandidatesByJobpost(match.params.id);
  // const [interview_data] = useSelectAllInterviewByJobPosts(match.params.id);
  const [status_data] = useSelectAllCandidatestatus();
  // const [Interviews] = useAllInterviews();
  const [interviews_data] = useInterviewsByJobpost(match.params.id);
  const [interviews_stats] = useInterviewsByJobpostStats(match.params.id);
  const { interviews_by_jobpost, all_interviews } = interviews_data;
  const { interviews_by_jobpost_stats } = interviews_stats;
  const { loading, jobpost } = data;

  // const { all_interviews } = interview_data;
  const { all_candidatestatuss } = status_data;

  const { candidates_by_jobpost } = candidates_data;

  // console.log("interview_data", all_interviews);
  const getTotalOfStatus = (status) => {
    if (candidates_by_jobpost && candidates_by_jobpost.length > 0) {
      const filteredData = candidates_by_jobpost.filter(
        (item) => item.candidate_status == status
      );
      return filteredData.length;
    } else {
      return 0;
    }
  };

  const [featuredImage, setFeaturedImage] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [updateData] = useUpdateJobpost();
  const publishOnCareerPage = async (status) => {
    const values = {};
    values.post_on_career_page = status;
    const data = await convertToFormData({ values, featuredImage });
    await updateData(jobpost._id, data);
    window.location.reload();
  }

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        <FilterDateComponent link={`/jobposts/${match.params.id}/view`} />
        {

          //   <div>
          //   <div id="miniaresult">
          //     <div
          //     // className="page-content"
          //     >
          //       <div className="container-fluid">
          //         {/* start page title */}
          //         <div className="row">
          //           <div className="col-12">
          //             <div
          //               style={{
          //                 background: "#fff",
          //                 padding: "15px 20px 5px 20px",
          //                 marginBottom: "10px",
          //                 boxShadow: "rgb(227 233 243) 0px 4px 22px",
          //                 borderRadius: "5px",
          //                 border: "1px solid #f1f1f1",
          //               }}
          //             >
          //               <div
          //                 className="page-title-box d-sm-flex align-items-center
          //       justify-content-between"
          //               >
          //                 <div className="container-fluid">
          //                   <div className="row">
          //                     {all_candidatestatuss &&
          //                       all_candidatestatuss.map((item) => {
          //                         return (
          //                           <div className="col-md-2 br-top p-15px">
          //                             <div
          //                               className="stats-data"
          //                               style={{ textAlign: "center" }}
          //                             >
          //                               {item.name}
          //                             </div>
          //                             <div style={{ textAlign: "center" }}>
          //                               {getTotalOfStatus(item.name)}
          //                             </div>
          //                           </div>
          //                         );
          //                       })}
          //                   </div>
          //                 </div>
          //                 <div className="page-title-right">
          //                   <ol className="breadcrumb m-0"></ol>
          //                 </div>
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //     {/* End Page-content */}
          //   </div>
          // </div>
        }

        {!loading ? (
          jobpost && (
            <SingleView
              data={jobpost}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={jobpost._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            >

              <div className="col-md-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Share Jobpost:</div>
                  </div>
                  <div className="card-body">
                    <div className="social-btns">
                      <div>
                        <LinkedinShareButton title={jobpost.posting_title} summary={jobpost.description} url={`https://accordmanpower.com/job-post/${jobpost._id}?source=LinkedIn`} source={`https://accordmanpower.com/job-post/${jobpost._id}`}>
                          <button className="btn btn-primary">LinkedIn</button>
                        </LinkedinShareButton>
                      </div>
                      <div>
                        <FacebookShareButton url={`https://accordmanpower.com/job-post/${jobpost._id}?source=Facebook`} quote={`New Job Opportunity`} hashtag={`#jobs`} >
                          <button className="btn btn-info">Facebook Page</button>
                        </FacebookShareButton>
                      </div>

                    </div>
                    <hr />
                    <div className="pt-2">
                      <p>Posted on Career Page</p>
                    </div>
                    {jobpost && jobpost.post_on_career_page && jobpost.post_on_career_page === "YES" ? (
                      <div >
                        <div className=" ">
                          <p> Published</p>
                          <button className="btn btn-warning" onClick={() => publishOnCareerPage("NO")}>Unpublish </button>

                        </div>
                      </div>
                    ) : (

                      <div className=" ">
                        <p>Not Published</p>
                        <button className="btn btn-warning" onClick={() => publishOnCareerPage("YES")} >Publish </button>

                      </div>
                    )}

                  </div>
                </div>
              </div>
            </SingleView>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <ScheduleInterview
              job_post_id={match.params.id}
              jobpost={jobpost}
            />
          </div>
        </div>

        <AllCandidatesComponent
          label={"position_name"}
          value={match.params.id}
        />
        <AllInterviewComponent
          label={"posting_title"}
          value={match.params.id}
        />
      </div>
    </div>
  );
};

export default ViewJobpost;