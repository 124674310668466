import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addInterview,
  getInterviews,
  getInterview,
  editInterview,
  deleteInterview,
  getAllInterviews,
  getAllInterviewsByJobPosts,
  addInterviewCandidate,
  getInterviewsStats,
} from "../../store/actions/interview_action";
import { SEARCH_TERMS } from "../enums/interviews_enum";

import {
  useAllJobposts,
  useSelectAllJobpost,
} from "../../shared/hooks/UseJobpost";
import { useSelectAllCandidate } from "../../shared/hooks/UseCandidate";
import { useSelectAllEmployee } from "../../shared/hooks/UseEmployee";
import { useSelectAllCandidatestatus } from "../../shared/hooks/UseCandidatestatus";
import _debounce from "lodash/debounce";

// Get All Data
export const useAllInterviews = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.interview);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getInterviews({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteInterview(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    // setPageNumber(1);
    dispatch(
      getInterviews({
        pageNumber,
      })
    );
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get All Data
export const useAllInterviewsByCondition = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.interview);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [params, setParams] = useState(null);

  useEffect(() => {
    if (params && params.length > 0) {
      dispatch(
        getInterviews({
          pageNumber,
          params,
        })
      );
    }
  }, [params, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getInterviews({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteInterview(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    // setPageNumber(1);
    if (params) {
      dispatch(
        getInterviews({
          pageNumber,
          params,
        })
      );
    }
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, setParams, deleteBtnClicked];
};

export const useAllInterviewsStats = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.interview);
  const [params, setParams] = useState(null);

  useEffect(() => {
    dispatch(
      getInterviewsStats({
        params,
      })
    );
  }, [params]);

  useEffect(() => {
    dispatch(
      getInterviewsStats({
        params,
      })
    );
  }, [window.location.search]);

  return [data, setParams];
};

// Get Single Data
export const useSingleInterview = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.interview);
  useEffect(() => {
    dispatch(getInterview(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateInterview = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addInterview(data));
  };
  return [addData];
};
// Add Data
export const useCreateInterviewCandidate = () => {
  const dispatch = useDispatch();
  const addData = async (data) => {
    await dispatch(addInterviewCandidate(data));
  };
  return [addData];
};
export const useUpdateInterview = () => {
  const dispatch = useDispatch();
  const updateData = async (id, data) => {
    await dispatch(editInterview(id, data));
  };
  return [updateData];
};

export const useSelectAllInterview = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.interview);
  useEffect(() => {
    dispatch(getAllInterviews({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};
export const useSelectAllInterviewByJobPosts = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.interview);
  useEffect(() => {
    dispatch(getAllInterviewsByJobPosts(id));
  }, []);
  return [data];
};

export const useGetDropdownOptions = () => {
  const [jobpost] = useSelectAllJobpost();
  const [interview] = useSelectAllInterview();
  const [candidate] = useSelectAllCandidate();
  const [employee] = useSelectAllEmployee();
  const [candidatestatus] = useSelectAllCandidatestatus();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (jobpost && jobpost.all_jobposts) {
      const newData = jobpost.all_jobposts.map((item) => {
        return {
          label: `${item.posting_title}-(${item.client_name && item.client_name.client_name
            })`,
          value: item._id,
        };
      });
      setDropdownOptions({ ...dropdownOptions, posting_title: newData });
    }
  }, [jobpost]);
  console.log(jobpost.all_jobposts);
  // useEffect(() => {
  //     if (jobpost && jobpost.all_jobposts) {
  //         const newData = jobpost.all_jobposts.map((item) => {
  //             return { label: item.client_name, value: item._id };
  //         });
  //         setDropdownOptions({ ...dropdownOptions, posting_client: newData });
  //     }
  // }, [jobpost]);

  // useEffect(() => {
  //     if (interview && interview.all_interviews) {
  //         const newData = interview.all_interviews.map((item) => {
  //             return { label: item.interview_name, value: item._id };
  //         });
  //         setDropdownOptions({ ...dropdownOptions, posting_title: newData });
  //     }
  // }, [interview]);

  // useEffect(() => {
  //   if (jobpost && jobpost.all_jobposts) {
  //     const newData = jobpost.all_jobposts.map((item) => {
  //       return { label: item.first_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, jobpost_name: newData });
  //   }
  // }, [jobpost]);

  useEffect(() => {
    if (candidate && candidate.all_candidates) {
      const newData = candidate.all_candidates.map((item) => {
        return { label: item.first_name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, candidate_name: newData });
    }
  }, [candidate]);

  useEffect(() => {
    if (candidatestatus && candidatestatus.all_candidatestatuss) {
      const newData = candidatestatus.all_candidatestatuss.map((item) => {
        return { label: item.name, value: item.name };
      });
      setDropdownOptions({ ...dropdownOptions, candidate_status: newData });
    }
  }, [candidatestatus]);

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, interviewer: newData });
    }
  }, [employee]);

  return [dropdownOptions];
};
