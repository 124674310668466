import React from "react";
import { Link } from "react-router-dom";

function TableViewBtn({ id, item }) {
  return (
    <Link
      title="View"
      to={`/${item}/${id}/view`}
      className="btn btn-soft-light btn-sm btn-primary m-1"
    >
      <i className="fa fa-eye"></i>
    </Link>
  );
}

export default TableViewBtn;
