export const GET_JOBPOSTS = "GET_JOBPOSTS";
export const ADD_JOBPOST = "ADD_JOBPOST";
export const EDIT_JOBPOST = "EDIT_JOBPOST";
export const GET_JOBPOST = "GET_JOBPOST";
export const RESET_JOBPOST = "RESET_JOBPOST";
export const ERROR_JOBPOST = "ERROR_JOBPOST";
export const GET_ALL_JOBPOSTS = "GET_ALL_JOBPOSTS";
export const INTERVIEWS_BY_JOBPOST = "INTERVIEWS_BY_JOBPOST";
export const INTERVIEWS_STATS_BY_JOBPOST = "INTERVIEWS_STATS_BY_JOBPOST";
export const CANDIDATES_BY_JOBPOST = "CANDIDATES_BY_JOBPOST";

export const JOBPOST_STATS_STARTED = "JOBPOST_STATS_STARTED";
export const JOBPOST_STATS = "JOBPOST_STATS";
export const JOBPOST_STATS_ENDED = "JOBPOST_STATS_ENDED";
