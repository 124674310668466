import moment from 'moment';
export const PAGE_TITLE = 'Requirements';
export const PAGE_SINGLE_TITLE = 'Requirement';
export const LINK_URL = 'jobposts';
export const SEARCH_TERMS = ['posting_title', 'meta_title'];
export const inputFields = {
  posting_title: {
    type: 'string',
    required: true,
    title: 'Posting Title',
  },
  client_name: {
    type: 'related',
    required: false,
    title: 'Client Name',
    field: 'client_name',
  },
  contact_name: {
    type: 'related',
    required: false,
    title: 'Contact Name',
    field: 'first_name',
  },
  account_manager: {
    type: 'related',
    required: false,
    title: 'Account Manager',
    field: 'name',
  },
  assigned_recruiter: {
    type: 'related',
    required: false,
    title: 'Assigned Recruiter',
    field: 'name',
  },
  date_opened: {
    type: 'string',
    required: false,
    title: 'Date opened',
    inputType: 'date',
    inputType: 'date',
  },
  target_date: {
    type: 'string',
    required: false,
    title: 'Target Date',
    inputType: 'date',
    inputType: 'date',
  },
  job_type: {
    type: 'select',
    required: false,
    title: 'Job Type',
    options: [
      'Full Time',
      'Any',
      'Part Time',
      'Permanent',
      'Contact',
      'Temporary',
      'Training',
      'Volunteer',
      'Seasonal',
      'Freelance',
    ],
  },
  job_opening_status: {
    type: 'select',
    required: false,
    title: 'Job Opening Status',
    options: [
      // 'Waiting for approval',
      // 'Submitted by Client',
      // 'Cancelled',
      // 'Filled',
      // 'Declined',

      'In-progress',
      'On-hold',
      'Inactive',
      'Clients Feedback Pending',
      'Closed By Accord',
      'Open Position',
    ],
  },
  work_experience: {
    type: 'select',
    required: false,
    title: 'Work Experience',
    options: ['Fresher', '0-1 year', '1-3 years', '4-5 years', '5+ years'],
  },
  industry: {
    type: 'related',
    required: false,
    title: 'Industry',
    static: true,
  },
  skill_set: {
    type: 'related',
    multiple: true,
    required: false,
    title: 'Skill Set',
    static: true,
  },
  salary: {
    type: 'string',
    required: false,
    title: 'Salary',
  },
  address_information_divider: {
    type: 'divider',
    title: 'Address Information',
  },
  remote_job: {
    type: 'checkbox',
    title: 'Remote Job',
    required: false,
  },
  city: {
    type: 'string',
    title: 'City',
    required: false,
  },
  state: {
    type: 'string',
    title: 'State/Province',
    required: false,
  },
  country: {
    type: 'string',
    title: 'Country',
    required: false,
  },
  zip_code: {
    type: 'string',
    title: 'Zip/Postal code',
    required: false,
  },
  forcast_details_divider: {
    type: 'divider',
    title: 'Forecast Details Information',
  },
  revenue_per_position: {
    type: 'string',
    required: false,
    title: 'Revenue Per Position',
  },
  actual_revenue: {
    type: 'string',
    required: false,
    title: 'Actual Revenue',
  },
  expected_revenue: {
    type: 'string',
    required: false,
    title: 'Expected Revenue',
  },
  missed_revenue: {
    type: 'string',
    required: false,
    title: 'Missed Revenue',
  },
  number_of_positions: {
    type: 'string',
    required: false,
    title: 'Number of Positions ',
  },
  description_information_divider: {
    type: 'divider',
    title: 'Description Information',
  },
  //sales values //Preview
  job_description: {
    type: 'html',
    required: false,
    title: 'Job Description ',
  },
  requirement: {
    type: 'html',
    required: false,
    title: 'Requirements',
  },
  benefits: {
    type: 'html',
    required: false,
    title: 'Benefits',
  },
  attachment_information_divider: {
    type: 'divider',
    title: 'Attachment Information',
  },
  job_summary: {
    type: 'file',
    required: false,
    title: 'Job Summary',
  },
  other: {
    type: 'file',
    required: false,
    title: 'Other',
  },
  post_on_career_page: {
    type: 'select',
    required: false,
    title: 'Posted on Career Page',
    options: ['YES', 'NO'],
  },
};
export const initialValues = {
  posting_title: '',
  client_name: '',
  contact_name: '',
  account_manager: '',
  assigned_recruiter: '',
  date_opened: '',
  target_date: '',
  job_type: '',
  job_opening_status: '',
  work_experience: '',
  industry: '',
  skill_set: '',
  salary: '',
  remote_job: false,
  city: '',
  state: '',
  country: '',
  zip_code: '',
  revenue_per_position: '',
  actual_revenue: '',
  expected_revenue: '',
  missed_revenue: '',
  number_of_positions: '',
  job_description: '',
  requirement: '',
  benefits: '',
  job_summary: '',
  other: '',
};

export const view_all_table = [
  { name: 'Profile', value: 'posting_title' },
  {
    name: 'Client',
    value: 'client_name',
    related: true,
    field: 'client_name',
    is_link: true,
    link_field: 'clients',
  },
  {
    name: 'No. of positions',
    value: 'number_of_positions',
  },
  {
    name: 'CTC Budget (LPA)',
    value: 'salary',
  },
  // { name: "Contact Name", value: "contact_name", related: true, field: "first_name" },

  {
    name: 'Account Manager',
    value: 'account_manager',
    related: true,
    field: 'name',
    is_link: true,
    link_field: 'employees',
  },

  {
    name: 'Req. Recd  Date',
    value: 'date_opened',
  },

  {
    name: 'Allocated To',
    value: 'assigned_recruiter',
    related: true,
    field: 'name',
    is_link: true,
    link_field: 'employees',
  },
  { name: 'Job Opening Status', value: 'job_opening_status' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'posting_title',
    field: 'posting_title',
    label: 'Posting Title',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'client_name',
    field: 'client_name',
    label: 'Client Name',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'contact_name',
    field: 'contact_name',
    label: 'Contact Name',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'account_manager',
    field: 'account_manager',
    label: 'Account Manager',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'assigned_recruiter',
    field: 'assigned_recruiter',
    label: 'Assigned Recruiter',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },

  {
    id: 'job_type',
    field: 'job_type',
    label: 'Job Type',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'job_opening_status',
    field: 'job_opening_status',
    label: 'Job Opening Status',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'work_experience',
    field: 'work_experience',
    label: 'Work Experience',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'industry',
    field: 'industry',
    label: 'Industry',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
  // {
  //   field: "skill_set",
  //   label: "Skill Set",
  //   type: "related",
  //   search_type: "exact",
  //   inputType: "text",
  //   condition: "",

  // },
  {
    id: 'city',
    field: 'city',
    label: 'City',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
