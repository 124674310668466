import {
  GET_CANDIDATESTATUSS,
  ADD_CANDIDATESTATUS,
  EDIT_CANDIDATESTATUS,
  GET_CANDIDATESTATUS,
  RESET_CANDIDATESTATUS,
  GET_ALL_CANDIDATESTATUSS,
  ERROR_CANDIDATESTATUS,
} from "../types/candidatestatus_type";

const initialState = {
  candidatestatuss: null,
  page: null,
  pages: null,
  candidatestatus: null,
  total_candidatestatuss: 0,
  loading: true,
  error: {},
  candidatestatus_message: null,
  all_candidatestatuss: null,
};

export const candidatestatus_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CANDIDATESTATUSS:
      return {
        ...state,
        candidatestatuss: payload.candidatestatuss,
        pages: payload.pages,
        page: payload.page,
        total_candidatestatuss: payload.count,
        loading: false,
        error: {},
      };
    case GET_ALL_CANDIDATESTATUSS:
      return {
        ...state,
        all_candidatestatuss: payload,
        loading: false,
        error: {},
      };

    case RESET_CANDIDATESTATUS:
      return {
        ...state,
        candidatestatus: null,
        loading: true,
        error: {},
      };

    case ADD_CANDIDATESTATUS:
      return {
        ...state,
        candidatestatus_message: payload,
        loading: false,
        error: {},
      };
    case GET_CANDIDATESTATUS:
      return {
        ...state,
        candidatestatus: payload,
        loading: false,
        error: {},
      };
    case EDIT_CANDIDATESTATUS:
      return {
        ...state,
        candidatestatus_message: payload,
        loading: false,
        error: {},
      };
    case ERROR_CANDIDATESTATUS:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
                    