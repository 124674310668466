import {
    GET_ATTENDANCES,
    ADD_ATTENDANCE,
    EDIT_ATTENDANCE,
    GET_TODAY_ATTENDANCE,
    GET_MONTHLY_ATTENDANCE,
    GET_TODAY_ATTENDANCE_EMPLOYEE,
    GET_MONTHLY_ATTENDANCE_EMPLOYEE,
} from "../types/attendance_type";

const initialState = {
    attendance: null,
    today_attendance: null,
    monthly_attendance: null,
    todays_attendance_employee: null,
    monthly_attendance_employee: null,
    loading: true,
    error: {},
};

export const attendance_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ATTENDANCES:
            return {
                ...state,
                attendances: payload,

                loading: false,
                error: {},
            };
        case GET_TODAY_ATTENDANCE:
            return {
                ...state,
                todays_attendance: payload,
                loading: false,
                error: {},
            };
        case GET_MONTHLY_ATTENDANCE:
            return {
                ...state,
                monthly_attendance: payload,
                loading: false,
                error: {},
            };
        case GET_TODAY_ATTENDANCE_EMPLOYEE:
            return {
                ...state,
                todays_attendance_employee: payload,
                loading: false,
                error: {},
            };
        case GET_MONTHLY_ATTENDANCE_EMPLOYEE:
            return {
                ...state,
                monthly_attendance_employee: payload,
                loading: false,
                error: {},
            };
        case ADD_ATTENDANCE:
            return {
                ...state,
                attendance_message: payload,
                loading: false,
                error: {},
            };
        case EDIT_ATTENDANCE:
            return {
                ...state,
                attendance_message: payload,
                loading: false,
                error: {},
            };
        default:
            return state;
    }
};
